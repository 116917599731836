import { classNames } from "repoV2/utils/common/render/classNames";
import React, { useState, useEffect } from "react";
import { isEmpty, CUSTOM_QUESTIONS } from "@Utils";
import { ICustomQuestions } from "../ICustomQuestions";
import styles from "../../../userDetails.module.scss";

interface ISelected {
    [key: string]: {
        checked: boolean;
        customText?: string | undefined;
    };
}

const MultiSelectQuestion = ({
    item,
    htmlId,
    userInput,
    showInputError,
    answers,
    setAnswers,
}: ICustomQuestions.IMultiSelectQuestionProps) => {
    const [selected, setSelected] = useState<ISelected>({});

    const castedUserInput = (userInput as string[]) || [];

    const choices = item.choices.map(c => ({
        label: c.includes(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX)
            ? c.replace(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX, "")
            : c,
        key: c,
        showOther: c.startsWith(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX),
    }));

    useEffect(() => {
        const newState: ISelected = Object.assign(
            {},
            ...item.choices.map(i => ({
                [i]: { checked: false, customText: undefined },
            }))
        );
        setSelected(newState);
    }, [item]);

    useEffect(() => {
        const newAnswerState = Object.entries(selected)
            .filter(([, selectionData]) => !!selectionData?.checked)
            .map(([selectedLabel, selectionData]) =>
                selectedLabel.includes(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX)
                    ? selectionData.customText || ""
                    : selectedLabel
            );

        setAnswers({ ...answers, [item.uuid]: newAnswerState });
    }, [selected]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e) return;

        setSelected(selectedState => ({
            ...selectedState,
            [e.target.value]: {
                ...selectedState[e.target.value],
                checked: e.target.checked,
            },
        }));
    };

    const otherOption = Object.entries(selected).find(
        ([label, options]) =>
            label.includes(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX) && options.checked
    );

    const isOtherSelected = !!otherOption?.[1]?.checked;

    const setCustomValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const otherTextOption = Object.keys(selected).find(label =>
            label.includes(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX)
        );

        if (!otherTextOption) return;

        setSelected(selectedProps => ({
            ...selectedProps,
            [otherTextOption]: {
                checked: true,
                customText: e?.target?.value || "",
            },
        }));
    };

    return (
        <>
            <div className={classNames(styles.fieldContainer, styles.flexCol)}>
                {choices.map((choice, i: number) => (
                    <label
                        id={choice.key}
                        key={choice.key}
                        htmlFor={`${htmlId}-${i}`}
                        className={styles.inputContainer}
                    >
                        <input
                            id={`${htmlId}-${i}`}
                            type="checkbox"
                            name={item.uuid}
                            value={choice.key}
                            checked={selected?.[choice.key]?.checked ?? false}
                            onChange={handleChange}
                        />
                        {choice.label}
                    </label>
                ))}
                {isOtherSelected ? (
                    <div
                        className={classNames(
                            styles.otherTextContainer,
                            styles.forMultiSelect
                        )}
                    >
                        <input
                            name={item.uuid}
                            id={`${item.uuid}-other`}
                            placeholder="Enter Answer"
                            className={classNames(
                                styles.formInput,
                                styles.otherTextField,
                                showInputError &&
                                    otherOption?.[1].checked &&
                                    isEmpty(
                                        otherOption?.[1]?.customText?.trim()
                                    )
                                    ? styles.formInputError
                                    : false
                            )}
                            type="text"
                            onChange={setCustomValue}
                            value={otherOption?.[1]?.customText || ""}
                        />
                    </div>
                ) : null}
            </div>
            <div className={styles.fieldError}>
                {(showInputError &&
                    isEmpty(castedUserInput) &&
                    "Please select an option") ||
                    (showInputError &&
                        otherOption?.[1].checked &&
                        isEmpty(otherOption?.[1]?.customText?.trim()) &&
                        "Please enter a valid answer") || <>&nbsp;</>}
            </div>
        </>
    );
};

export default MultiSelectQuestion;
