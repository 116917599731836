import { useState } from "react";
import { useInterval } from "@restart/hooks";

/**
 * @deprecated use repoV2/features/Common/modules/Time/hooks/useTimer.ts
 */
export const useTimer = (
    // a hook to cause update every second
    // usually used when you want to display timers
    condition: boolean = true,
    updateState: ((...params: any) => any) | undefined = undefined
) => {
    const [timer, setTimer] = useState(0);

    function updateFn() {
        if (condition) {
            if (updateState) {
                updateState();
            } else {
                setTimer(timer + 1);
            }
        }
    }

    useInterval(updateFn, 1000, !condition);
};
