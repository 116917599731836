// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/BrandedCommunity/.constants folder

import { IBonusOfferingsReducerState } from "./BrandedCommunity.interface";

export const MY_PURCHASES_BLANK_SCREEN_SRC = // empty state image for my purchases
    "/static/images/brandedCommunity/empty_my_purchases.png";

export const DEFAULT_SECTIONS = {
    FEED: 1,
    CHAT_WITH_CREATOR: 2,
    BONUS_OFFERINGS: 3,
    MEMBERS: 4,
};

export const BONUS_OFFERINGS_TABS = {
    MY_PURCHASES: 1,
    ALL_BONUS_OFFERINGS: 2,
};

export const BONUS_OFFERINGS_TAB_OPTIONS = [
    {
        label: "My Purchases",
        value: BONUS_OFFERINGS_TABS.MY_PURCHASES,
    },
    {
        label: "All bonus offfering",
        value: BONUS_OFFERINGS_TABS.ALL_BONUS_OFFERINGS,
    },
];

export const bonus_offerings_reducer_initial_state: IBonusOfferingsReducerState =
    {
        myPurchasesData: {
            data: [],
            currentPage: 1,
            totalPages: 1,
            isFetched: false,
        },
    };

export const BONUS_OFFERINGS_ACTION_TYPES = {
    SET_MY_PURCHASES_DATA: "@BONUS_OFFERINGS/MY_PURCHASES/SET",
} as const;

export const DEFAULT_PAGE_FOR_MY_PURCHASES = 1;

export const GOOGLE_DRIVE_RESTRICT_ERROR_TEXT =
    "Google drive url not supported";

export const GOOGLE_DRIVE_ENABLED = false; // this to restrict google drive video link, in future if it work then only this flag need to change to true
