export const CHECKOUT_PAGES_ACTION_TYPES = {
    SET_PRICE_SECTION_DATA: "@CHECKOUT_PAGES/PRICE_SECTION/SET",
    SET_SESSIONS_DATA: "@CHECKOUT_PAGES/SESSIONS_DATA/SET",
    SET_PRICE_SUMMARY_DATA: "@CHECKOUT_PAGES/PRICE_SUMMARY_DATA/SET",
    SET_IS_PAYMENT_PROCESSING: "@CHECKOUT_PAGES/IS_PAYMENT_PROCESSING/SET",
    SET_IS_EXPRESS_INTEREST_PROCESSING:
        "@CHECKOUT/IS_EXPRESS_INTEREST_PROCESSING/SET",
    SET_CUSTOM_QUESTIONS_DATA: "@CHECKOUT/CUSTOM_QUESTIONS_DATA/SET",
    SET_IS_CUSTOM_QUESTION_FILE_UPLOADING:
        "@CHECKOUT/IS_CUSTOM_QUESTION_FILE_UPLOADING",
    SET_PART_PAYMENTS_DATA: "@CHECKOUT_PAGES/PART_PAYMENTS/SET",
    SET_ORDER_BUMPS_DATA: "@CHECKOUT_PAGES/ORDER_BUMPS/SET",
    SET_COUPON_CODE_PROCESSING: "@CHECKOUT_PAGES/COUPON_CODE_PROCESSING/SET",
    SET_COUPON_CODE_HELPER_DATA: "@CHECKOUT_PAGES/COUPON_CODE_HELPER_DATA/SET",
} as const;

/**
 * TODO: need to figure out nestinf as done for `FORM_KEYS` in `repoV2/features/Common/modules/FormData/FormData.constants.ts`
 */
export const CHECKOUT_REDUCER_ACCESS_KEYS = {
    listing_details: "listingDetails",
    session_details: "sessionDetails",
    gst_details: "gstDetails",
    international_details: "internationalDetails",
    active: "active",
    price_section: "priceSection",
    timezone_name: "name",
    custom_questions: "customQuestions",
    file_uploading_details: "fileUploadingDetails",
    part_payment_details: "partPaymentDetails",
    order_bumps_details: "orderBumpsDetails",
    coupon_code_processing: "couponCodeProcessing",
    coupon_code_helper_text: "couponCodeHelperText",
};
