// reference doc - https://posthog.com/docs/libraries/next-js

import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { useEffect } from "react";
import { NODE_ENV_IS_NOT_PRODUCTION } from "repoV2/constants/environment";
import { LOG_IN_REQUIRED_ROUTE_PATHNAMES } from "repoV2/constants/user&auth&login/login&logout";
import { isBrowser } from "repoV2/utils/common/render/window";

const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST;
const are_posthog_keys_present = POSTHOG_KEY && POSTHOG_HOST;

export const PostHog = ({ children }: { children: JSX.Element }) => {
    const router = useRouter();
    const enablePostHog =
        are_posthog_keys_present &&
        LOG_IN_REQUIRED_ROUTE_PATHNAMES.includes(router.pathname);

    useEffect(() => {
        // Check that PostHog is client-side (used to handle Next.js SSR)
        if (enablePostHog && isBrowser()) {
            posthog.init(POSTHOG_KEY, {
                api_host: POSTHOG_HOST,
                // Enable debug mode in development
                loaded: posthogInstance => {
                    if (NODE_ENV_IS_NOT_PRODUCTION) posthogInstance.debug();
                },
            });

            const handleRouteChange = () => posthog?.capture("$pageview");
            // Track page views
            router.events.on("routeChangeComplete", handleRouteChange);
            return () => {
                router.events.off("routeChangeComplete", handleRouteChange);
            };
        }
    }, []);

    return enablePostHog ? (
        <PostHogProvider client={posthog}>{children}</PostHogProvider>
    ) : (
        children
    );
};
