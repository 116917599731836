export const TIME_UNIT_KEYS = {
    YEARS: "years",
    MONTHS: "months",
    DAYS: "days",
    HOURS: "hours",
    MINUTES: "minutes",
    SECONDS: "seconds",
};

export type ITimeUnitKey =
    | typeof TIME_UNIT_KEYS.YEARS
    | typeof TIME_UNIT_KEYS.MONTHS
    | typeof TIME_UNIT_KEYS.DAYS
    | typeof TIME_UNIT_KEYS.HOURS
    | typeof TIME_UNIT_KEYS.MINUTES
    | typeof TIME_UNIT_KEYS.SECONDS;

export const SHORTENED_TIME_UNITS = {
    [TIME_UNIT_KEYS.YEARS]: "yrs",
    [TIME_UNIT_KEYS.MONTHS]: "mons",
    [TIME_UNIT_KEYS.DAYS]: "days",
    [TIME_UNIT_KEYS.HOURS]: "hrs",
    [TIME_UNIT_KEYS.MINUTES]: "mins",
    [TIME_UNIT_KEYS.SECONDS]: "secs",
};

export const SHORTEST_TIME_UNITS = {
    [TIME_UNIT_KEYS.YEARS]: "y",
    [TIME_UNIT_KEYS.MONTHS]: "m",
    [TIME_UNIT_KEYS.DAYS]: "d",
    [TIME_UNIT_KEYS.HOURS]: "h",
    [TIME_UNIT_KEYS.MINUTES]: "m",
    [TIME_UNIT_KEYS.SECONDS]: "s",
};

export const FULL_TIME_UNITS = {
    [TIME_UNIT_KEYS.YEARS]: "years",
    [TIME_UNIT_KEYS.MONTHS]: "months",
    [TIME_UNIT_KEYS.DAYS]: "days",
    [TIME_UNIT_KEYS.HOURS]: "hours",
    [TIME_UNIT_KEYS.MINUTES]: "minutes",
    [TIME_UNIT_KEYS.SECONDS]: "seconds",
};

export const DEFAULT_API_PAYLOAD_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const DEFAULT_DIAPLAY_DATE_FORMAT = "MMMM dd, yyyy";
export const DAY_DATE_MONTH_YEAR_FORMAT = "EEEE, dd MMMM yyyy"; // Wednesday, 12 June 2024
export const DAY_DATE_MONTH_YEAR_SHORT_FORMAT = "EEE, dd MMM yyyy"; // Wed, 12 Jun 2024
export const SHORT_DAY_DATE_MONTH_YEAR_FORMAT = "EEE, dd MMM ''yy"; // Wed, 12 Jun '24
export const SHORT_DAY_DATE_MONTH_YEAR = "EEEE, dd MMM ''yy"; // Wednesday, 12 Jun '24
export const DATE_MONTH_YEAR_FORMAT = "dd/MM/yyyy"; // 12/06/2024
export const DATE_MONTH_YEAR_FORMAT_DASH = "yyyy-MM-dd"; // 2024-06-12
export const BASIC_TIME_FORMAT = "hh:mm a"; // 02:00 AM
export const SHORT_TIME_FORMAT = "h:mmaaaaa'm'"; // 2:00am
export const LONG_TIME_FORMAT = "HH:mm"; // 23:05
export const WEEK_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;
export const DAY_MONTH_YEAR_FORMAT = "d MMMM yyyy";

export const FIFTEEN_MINUTES_IN_SECONDS = 15 * 60;
