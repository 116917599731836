import { IEvent, IHost, IUser } from "@Interfaces";
import { IFetchDataAction } from "@Redux/sagas/api";

import { IInstalment } from "@Modules/eventPage/components/PaymentPlan/IPaymentPlan";
import { SESSION_STORAGE_KEYS } from "repoV2/constants/common/storage/sessionStorage";
import { isInstallmentPaymentType } from "repoV2/utils/common/listing";
import { makePaymentViaStripe } from "repoV2/utils/payment/Stripe/makePaymentViaStripe";
import { makePaymentViaTazapay } from "repoV2/utils/payment/Tazapay/makePaymentViaTazapay";
import {
    CURRENCY_STRINGS,
    GATEWAYS_ENUM,
    TRANSACTION_STATUSES,
} from "repoV2/constants/payment";
import { VARIANT_TYPE_IDS } from "@Modules/eventPage/components/OfferingVariationSelector/OfferingVariationSelector.constants";
import { appendURLSearchParams } from "repoV2/utils/urls&routing/urlParams";
import { getReturnUrl } from "repoV2/utils/payment/getReturnUrl";
import { BILLING_DISPLAY_FORMAT as _BILLING_DISPLAY_FORMAT } from "repoV2/features/Listing/Listing.constants";
import {
    getSessionStorageItem,
    removeSessionStorageItem,
    setSessionStorageItem,
} from "../repoV2/utils/common/storage/getterAndSetters";
import {
    chainShortCircuits,
    cumulationPipe,
    isBrowser,
    roundUp,
} from "./common";
import {
    DEFAULT_PAYMENT_GATEWAY,
    EVENT_TYPE,
    GST_STATUS,
    STORAGE_KEYS,
} from "./constants";
import {
    getIsSingleSlot,
    getListingIsClass,
    listingIsMerch,
    listingIsSlotType,
} from "./event";

export module IBookingData {
    export interface IProps {
        eventData: any;
        slots: Array<string>;
        quantity?: number;
        customerAddress?: string;
        username: string | null;
        hostName?: string;
        gatewaysSupported: Array<string>;
        numberOfCustomers?: number;
        otherListings?: IEvent.IPaymentInfo["otherListings"];
    }
    export interface IReturnType {
        username: string | null;
        listingID: string;
        variantID?: string;
        customerAddress?: string;
        hostName?: string;
        gatewaysSupported: Array<string>;
        numberOfCustomers: number;
        otherListings: string[]; // List of UUIDs
        listingMap: {
            [uuid: string]: {
                quantity?: number;
                slot_uids?: string[];
                class_uid?: string;
            };
        };
    }
}

// Get the data to be used by the payment flow
export const getBookingData = ({
    eventData,
    slots,
    quantity,
    customerAddress,
    username = "", // This will be the username of the first customer (who will be paying) in the list of user
    hostName,
    gatewaysSupported,
    numberOfCustomers = 1,
    otherListings = {},
}: IBookingData.IProps): IBookingData.IReturnType => {
    const { type: eventType, uuid: listingID, selectedVariant } = eventData;

    return {
        numberOfCustomers,
        listingID,
        hostName,
        gatewaysSupported,
        username,
        ...(customerAddress && { customerAddress }),
        otherListings: Object.keys(otherListings),
        listingMap: {
            [listingID]: {
                ...(listingIsMerch(EVENT_TYPE.MERCHANDISE) && {
                    quantity,
                    ...(selectedVariant && {
                        variant_uid: selectedVariant.uuid,
                    }),
                }),
                ...(getListingIsClass(eventType) && { class_uid: slots[0] }),
                ...(listingIsSlotType(eventType) && {
                    slot_uids: slots,
                }),
            },
            ...otherListings,
        },
    };
};

/**
 * @deprecated use the one this is made equal to\
 * \
 * This is to customise in what format and how much of the billing information is to be shown
 *
 * - `DEFAULT_SHOW_ALL` is the default behaviour. CGST inclusive unit price will be shown if CGST is enabled.
 *
 * - `SEPARATE_CGST` is just a slight variation on `SHOW_ALL` where the CGST will be shown separately
 * in the bill, and just the base price will be shown on the event card on host page
 *
 * - `PRETEND_CGST_INAPPLICABLE` will not show any billing details (even the Review Details
 * section will not be expandable). The user will only get to see the
 * CGST + Booking fees at the payment gateway
 */

export const BILLING_DISPLAY_FORMAT = _BILLING_DISPLAY_FORMAT;

export type IBillingDisplayFormat =
    typeof BILLING_DISPLAY_FORMAT[keyof typeof BILLING_DISPLAY_FORMAT];

export module IPaymentInfoForSingleOffering {
    export interface IEventDataNeededMetaData extends IEvent.IEventMetadata {}
    export interface IEventDataNeeded {
        // in case of upsell offering additional_listing_uid is listing_uuid  and uuid is upsell_uuid
        additional_listing_uid?: string;
        uuid: string;
        creator_gst: number;
        platform_gst: number;
        type: number;
        internet_handling_fees: number;
        gst_state: IEvent.IGSTStatus;
        updated_price: number;
        price_per_head?: number;
        shipping_cost?: number;
        currency_name?: string;
        availability_choice?: number;
        allow_customer_scheduling_post_payment?: boolean;
        gst_summary_type?: number;
        selectedOfferingVariation?: IEvent.IOfferingVariation | null;
        shipping_details?: IEvent.IShippingDetails;
        selectedVariant?: IEvent.IVariant;
        enable_shiprocket?: boolean;
        variantsData?: IEvent.IVariantsData;
        metadata?: IEventDataNeededMetaData;
    }

    export interface IProps {
        isUpsellListing?: boolean;
        eventData: IEventDataNeeded | null;
        discountData?: IEvent.IDiscount["discountData"];
        customPrice?: IEvent.IPaymentInfo["customPrice"];
        quantity?: IEvent.IPaymentInfo["quantity"];
        slots?: IEvent.IPaymentInfo["slots"];
        numberOfCustomers?: IEvent.IPaymentInfo["numberOfCustomers"];
        flags?: {
            hideDiscount?: boolean;
            includedGSTPrice?: boolean; // For universal payment
        };
        billingDisplayFormatState?: IBillingDisplayFormat;
        selectedPaymentType?: number;
        initialInstalment?: IInstalment;
        earlyBirdObj?: IEvent.IEarlyBird | null;
    }
    export interface IReturn {
        uuid: string;
        flags: {
            isMerchandise: boolean;
            isSingleSlot: boolean;
            enableGST: boolean;
            billingDisplayFormatState: IBillingDisplayFormat;
            hasPincode: boolean;
            shiprocketEnabled?: boolean;

            showAllForCGst: boolean;
            separateCgst: boolean;
            pretendCgstInapplicable: boolean;
        };
        percentages: {
            internetFees: number;
            gst: number;
            creatorGst: number;
        };
        priceValues: {
            // Basic price of 1 unit of the item, excluding ANY discounts or early bird overrides
            unitPrice: number;
            netUnitPrice: number;
            creatorGstPrice: number;
            cgstExclusiveUnitPrice: number;
            cgstExclusiveShippingPrice: number;
            price: number;
            internetHandlingFees: number;
            gstPrice: number;
            totalPrice: number;
            totalPriceWOEarlyBird: number;
            shippingPrice?: number;
        };
        factors: {
            billingMultiplier: number;
            discountScalingFactor: number;
            gstScalingFactor: number;
            earlyBirdScalingFactor: number;
        };
        discounts: {
            discountPrice: number;
            discountPriceForSingleCustomer: number;
        };
    }
}

export const evaluateBillingMultiplier = ({
    eventData,
    slots,
    quantity,
}: {
    eventData: IPaymentInfoForSingleOffering.IEventDataNeeded | null;
    slots: Array<string>;
    quantity?: number;
}) => {
    const nullCheckedEventData =
        eventData || ({} as IPaymentInfoForSingleOffering.IEventDataNeeded);
    const eventType = nullCheckedEventData.type;
    const metadata =
        nullCheckedEventData.metadata ||
        ({} as IPaymentInfoForSingleOffering.IEventDataNeededMetaData);

    const isMerchandise: boolean = eventType === EVENT_TYPE.MERCHANDISE;
    const isCommunity: boolean = eventType === EVENT_TYPE.BRANDED_COMMUNITY;
    const isFlexibleAppointment =
        eventType === EVENT_TYPE.APPOINTMENTS &&
        metadata.is_flexible_scheduling;
    // All the types of events that do not have a variance in the number of slots or quantity
    const isSingleSlot: boolean = getIsSingleSlot({ eventData });
    const hasBatchedSessions =
        metadata.batched_flexible_sessions_count !== undefined;
    const batchedSessionsCount = metadata.batched_flexible_sessions_count;
    const isWhatsappCommunity: boolean = eventType === EVENT_TYPE.WHATSAPP;
    const isTelegram: boolean = eventType === EVENT_TYPE.TELEGRAM;

    // Represents the multiplication to be performed for the number of units
    const billingMultiplier: number = chainShortCircuits(
        [
            // this case has been added as a customer can pay without booking a slot also for the case
            // where we allow customer to schedule slots post payment
            // hence setting billing multiplier as 1 when slot lenght is 0
            [
                eventData?.allow_customer_scheduling_post_payment &&
                    slots.length === 0 &&
                    !isFlexibleAppointment,
                1,
            ],

            [isMerchandise, quantity!],
            [isCommunity, quantity],
            [
                isFlexibleAppointment,
                hasBatchedSessions ? batchedSessionsCount : quantity!,
            ],
            [isSingleSlot, 1],
            [isTelegram, 1],
            [isWhatsappCommunity, quantity!],
        ],
        slots.length
    );
    return billingMultiplier;
};

/**
 * Calculates the discounted price based on the provided parameters.
 *
 * @param {Object} params - An object containing parameters for calculating the discounted price.
 * @param {number} params.basePriceForDiscount - The base price used for calculating the discount.
 * @param {boolean} params.hideDiscount - A flag indicating whether the discount should be hidden.
 * @param {boolean} params.isPartPaymentSelected - A flag indicating whether part payment is selected.
 * @param {""|IEvent.IDiscountType} params.discountType - The type of discount, either "percentage" or "absolute".
 * @param {number} params.discountByValue - The value of the discount (percentage or absolute amount).
 * @returns {number} The calculated discounted price.
 */
const getDiscountPrice = ({
    basePriceForDiscount,
    hideDiscount,
    isPartPaymentSelected,
    discountType,
    discountByValue,
}: {
    basePriceForDiscount: number;
    discountByValue: number;
    hideDiscount: boolean;
    isPartPaymentSelected: boolean;
    discountType: "" | IEvent.IDiscountType;
}) => {
    return Math.min(
        basePriceForDiscount, // The discount price deducted cannot be more than the base price
        hideDiscount
            ? 0
            : chainShortCircuits(
                  [
                      [isPartPaymentSelected, 0], // don't show discount price in payment summary for installment plan as it is calculated on backend and showing the discounted price on installment card
                      [!discountType, 0],
                      [
                          discountType === "percentage",
                          (basePriceForDiscount * discountByValue) / 100,
                      ],
                      [discountType === "absolute", discountByValue],
                  ],
                  0
              )
    );
};

export const calculatePaymentInfoForSingleOffering = ({
    eventData,
    isUpsellListing,
    discountData,
    customPrice,
    quantity,
    numberOfCustomers = 1,
    slots = [],
    flags,
    billingDisplayFormatState: billingDisplayFormatStateProp,
    initialInstalment,
    selectedPaymentType,
    earlyBirdObj,
}: IPaymentInfoForSingleOffering.IProps): IPaymentInfoForSingleOffering.IReturn => {
    const { hideDiscount = false, includedGSTPrice } = flags || {};
    const {
        selectedOfferingVariation,
        additional_listing_uid,
        uuid,
        creator_gst: creatorGST,
        platform_gst: platformGST,
        type: eventType,
        internet_handling_fees: internetFees,
        gst_state: gstState,
        shipping_details,
        enable_shiprocket: shiprocketEnabled,
        variantsData,
    }: IPaymentInfoForSingleOffering.IEventDataNeeded = eventData || {
        creator_gst: 0,
        platform_gst: 0,
        type: 0,
        internet_handling_fees: 0,
        updated_price: 0,
        uuid: "",
        gst_state: 1,
        currency_name: CURRENCY_STRINGS.INR,
    };

    const billingDisplayFormatState =
        billingDisplayFormatStateProp ??
        eventData?.gst_summary_type ??
        BILLING_DISPLAY_FORMAT.DEFAULT_SHOW_ALL;

    const showAllForCGst =
        billingDisplayFormatState === BILLING_DISPLAY_FORMAT.DEFAULT_SHOW_ALL;

    const separateCgst =
        billingDisplayFormatState === BILLING_DISPLAY_FORMAT.SEPARATE_CGST;

    const pretendCgstInapplicable =
        billingDisplayFormatState ===
        BILLING_DISPLAY_FORMAT.PRETEND_CGST_INAPPLICABLE;

    const gstFactor: number =
        1 +
        chainShortCircuits<number>(
            [
                [includedGSTPrice, includedGSTPrice], // includeGST can override the value set in gstState
                [
                    [GST_STATUS.INCLUSIVE, GST_STATUS.EXCLUSIVE].includes(
                        gstState
                    ),
                    creatorGST / 100,
                ],
            ],
            0
        );

    const isMerchandise: boolean = eventType === EVENT_TYPE.MERCHANDISE;
    const hasPincode = !!shipping_details?.pincode;
    const hasVariants = !!variantsData?.variants;

    // In case of merchandise
    // shippingPrice will only be added once, irrespective of how many items are bought
    const shippingPrice: number =
        (isMerchandise && eventData?.shipping_details?.shipping_charge) || 0;

    // No cgst will be charged for shipping for international payment
    const cgstExclusiveShippingPrice: number = shippingPrice / gstFactor;
    // console.log({ gstFactor, cgstExclusiveShippingPrice });

    const isSingleSlot = getIsSingleSlot({ eventData });

    const billingMultiplier: number = evaluateBillingMultiplier({
        eventData,
        slots,
        quantity,
    });

    // This is the initial price of the main offering. Does not include secondary prices like shipping, top-up, etc
    let initialPrice: number = roundUp(
        customPrice || eventData?.updated_price || 0
    );

    if (isMerchandise && hasVariants)
        initialPrice = eventData?.selectedVariant?.updated_price || 0;

    if (selectedOfferingVariation) {
        // show lto price if lto active and default variation selected
        if (
            !earlyBirdObj ||
            selectedOfferingVariation.variant_type === VARIANT_TYPE_IDS.others
        ) {
            initialPrice = selectedOfferingVariation?.variant_price_final || 0;
        }
    }

    const isPartPaymentSelected = !!(
        isInstallmentPaymentType(selectedPaymentType) && initialInstalment
    );

    if (isPartPaymentSelected) {
        const { discount_applied = 0, installment_price_final } =
            initialInstalment;
        initialPrice = installment_price_final - discount_applied;
    }

    // This is meant to account for secondary prices that are to be tagged along the initial price of the main product
    // Since shipping cost will be the same irrespective of number of items, it can be treated as being spread across
    // the number of items being bought, so the logic below avoids having to perform a separate parallel calculation for shippingCost
    // pincode is mandatory in case of shiprocket enabled
    // if shiprocket is not enabled, shipping price and shipping days will be displayed by default
    const netInitialPrice: number =
        initialPrice +
        (hasPincode || !shiprocketEnabled ? shippingPrice : 0) /
            (billingMultiplier || 1);

    // All terms with `initial price` will refer to just the price of the main offering
    const cgstExclusiveInitialPrice: number = initialPrice / gstFactor;
    // All terms with `unit price` will refer to price of all the offerings
    const cgstExclusiveUnitPrice: number = netInitialPrice / gstFactor;

    // Since CGST will only be charged for base price for international,
    // and for both base price and shipping price for domestic payments
    // const creatorGstPrice: number = isInternational
    //     ? initialPrice - cgstExclusiveInitialPrice
    //     : netInitialPrice - cgstExclusiveUnitPrice;

    const creatorGstPrice: number = netInitialPrice - cgstExclusiveUnitPrice;

    const basePrice: number =
        cgstExclusiveUnitPrice * billingMultiplier * numberOfCustomers;
    // Since discount is only applicable on the initial price of the main offering and not the secondary offerings
    const singleCustomerBasePriceForDiscount =
        cgstExclusiveInitialPrice * billingMultiplier;
    const basePriceForDiscount: number =
        singleCustomerBasePriceForDiscount * numberOfCustomers;

    // String that has the type of the discount
    const discountType: IEvent.IDiscountType | "" =
        (discountData?.type?.toLowerCase() as IEvent.IDiscountType) || "";

    // Internal value for this function, denotes the GST adjusted discount value for both absolute and percentage cases
    const discountByValue =
        (discountData?.value ?? 0) /
            (discountData?.include_gst ? gstFactor : 1) || 0;

    // Price to be subtracted
    const commonDiscountProps = {
        discountByValue,
        discountType,
        hideDiscount,
        isPartPaymentSelected,
    };
    const discountPrice = getDiscountPrice({
        basePriceForDiscount,
        ...commonDiscountProps,
    });
    const discountPriceForSingleCustomer = getDiscountPrice({
        basePriceForDiscount: singleCustomerBasePriceForDiscount,
        ...commonDiscountProps,
    });

    // Scaling factor for percentage discount
    const discountScalingFactor: number = gstFactor;

    // Scaling factor for the initial price of early bird
    const earlyBirdScalingFactor: number =
        gstState === GST_STATUS.EXCLUSIVE ? gstFactor : 1;

    // console.log({ basePrice, discountPrice });

    // Price after subtraction
    const discountedPrice: number = basePrice - discountPrice;
    // console.log({ discountedPrice });

    // Subtotal minus exly booking charges to be shown to the end user
    const subTotal = discountedPrice * gstFactor;

    // Price to be used for calculation of exly booking fees. This is
    // the price being charged by the user without the CGST
    // const priceForBookingFees = discountedPrice;

    // console.log({ subTotal }, `${discountedPrice}`);
    // console.log({ priceForBookingFees }, `${discountedPrice} / ${gstFactor}`);

    // * Note: This was earlier a parameter that only worked for merchandise,
    // and could be controlled from admin panel. It is being phased out on
    // the frontend, only to be used on the backend. To prevent a huge number
    // of changes all through the code, and an uncertain-ity about whether this
    // will be re-introduced this is being cast to true here.  Can be removed
    // later on if it has been decidedly established that this won't be needed

    const enableGST: boolean = true;

    const ihfFactor = enableGST ? 0.01 * internetFees : 0;
    const internetHandlingFees: number = ihfFactor * subTotal;

    // console.log(
    //     { internetHandlingFees },
    //     `${internetFees}/100 * ${priceForBookingFees}`
    // );

    const ihfGSTFactor = 0.01 * platformGST;
    const gstPrice: number = ihfGSTFactor * internetHandlingFees;
    // console.log({ gstPrice }, `${platformGST}/100 * ${internetHandlingFees}`);

    const totalPrice: number = Math.max(
        0,
        subTotal + internetHandlingFees + gstPrice
        // shippingPrice will be 0 normally, but wherever is it present,
        // it will only be used for calculating creator's gst, not exly's
        // gst and ihf. So it is added in the end here.
    );

    // console.log(
    //     { totalPrice },
    //     `${taxedPrice} + ${internetHandlingFees} + ${gstPrice}`
    // );

    // price per head always includes the gst amount in it
    const pricePerHead = eventData?.price_per_head || 0;
    // Getting the final value price exclusive of early bird, including the multipliers
    const priceWOEarlyBirdWMultipliers =
        pricePerHead * billingMultiplier * numberOfCustomers;
    // IHF amount is calculated on the base price including gst, multiplied by the ihf factor
    // Note - IHF is 0, incase creator selects the 'I will bear the booking fee' option
    // the 'pretendCgstInapplicable' is signifying that only
    const internetHandlingFeesWOEarlyBird: number = pretendCgstInapplicable
        ? 0
        : priceWOEarlyBirdWMultipliers * ihfFactor;
    // the gst on the IFH amount
    const gstOnInternetHandlingFeesWOEarlyBird: number = pretendCgstInapplicable
        ? 0
        : internetHandlingFeesWOEarlyBird * ihfGSTFactor;

    // Total price without early bird
    const totalPriceWOEarlyBird: number =
        priceWOEarlyBirdWMultipliers +
        internetHandlingFeesWOEarlyBird +
        gstOnInternetHandlingFeesWOEarlyBird;

    return {
        uuid:
            isUpsellListing && additional_listing_uid
                ? additional_listing_uid
                : uuid,
        // Boolean flags for certain conditions
        flags: {
            isMerchandise, // Is this listing a merchandise type
            isSingleSlot, // Is it a single slot type
            enableGST, // Whether to include GST? (Prerequisite is isMerchandise === true)
            billingDisplayFormatState,
            hasPincode,
            shiprocketEnabled,

            showAllForCGst,
            separateCgst,
            pretendCgstInapplicable,
        },
        // Percentage values[from the API, returning for ease of declaration]
        percentages: {
            internetFees,
            gst: platformGST, // Platform GST percentage, to be shown in the payment summary
            creatorGst: creatorGST, // GST being charged to the creator
        },
        // Absolute numbers for price values
        priceValues: {
            unitPrice: initialPrice, // Base unit price of the main offering. Excludes secondary prices.
            netUnitPrice: netInitialPrice, // Base unit price plus all the secondary prices like shipping, top-up, etc
            cgstExclusiveUnitPrice,
            cgstExclusiveShippingPrice,
            creatorGstPrice,
            price: netInitialPrice * billingMultiplier, // Grand total price to be paid minus taxes
            internetHandlingFees,
            gstPrice, // gst charged on internetHandlingFees
            totalPrice, // Final price, to be paid
            totalPriceWOEarlyBird, // price to be pain in case early bird offer was not there
            ...(isMerchandise && { shippingPrice }),
        },
        // Scaling factors
        factors: {
            billingMultiplier, // Number of instances of items being bought for this SKU
            discountScalingFactor, // To scale the discount value being shown as per what makes sense in the final calculation
            gstScalingFactor: gstFactor, // This is the gst scaling factor being used. Don't need to use it, but might need to.
            earlyBirdScalingFactor, // Scaling factor for early bird's initial price
        },
        discounts: {
            discountPrice: roundUp(discountPrice), // Price of the coupon discount. Defaults to 0
            discountPriceForSingleCustomer: roundUp(
                discountPriceForSingleCustomer
            ),
        },
    };
};

export const clearPaymentCache = (): void => {
    if (!isBrowser()) return;
    removeSessionStorageItem(STORAGE_KEYS.STRIPE_CUSTOMER_DETAILS);
    removeSessionStorageItem(STORAGE_KEYS.CUSTOMER_DETAILS);
    removeSessionStorageItem(STORAGE_KEYS.BOOKING_DATA);
    removeSessionStorageItem(STORAGE_KEYS.DISCOUNT_CODE);
};

export interface IBillingData {
    initialPrice: number;
    subtotal: number;
    discountPrice: number;
    discountPriceWScalingFactor: number;
    internetHandlingFees: number;
    gstPrice: number;
    totalPrice: number;
    totalPriceWOEarlyBird: number;

    cgstExclusiveUnitPrice: number;
    creatorGstPrice: number;
}

export const getBillingDataFromPaymentInfos = (
    paymentInfos: Array<IPaymentInfoForSingleOffering.IReturn>,
    numberOfCustomers: number
): IBillingData => ({
    initialPrice: cumulationPipe(paymentInfos, p => p.priceValues.unitPrice),
    // unitPrice, // Early Bird
    subtotal: cumulationPipe(
        paymentInfos,
        p => p.priceValues.netUnitPrice * p.factors.billingMultiplier
    ),

    // bookingFees,
    discountPrice: cumulationPipe(paymentInfos, p => p.discounts.discountPrice),
    discountPriceWScalingFactor: cumulationPipe(paymentInfos, p =>
        Math.min(
            p.priceValues.unitPrice *
                p.factors.billingMultiplier *
                numberOfCustomers,
            p.discounts.discountPrice * p.factors.discountScalingFactor
        )
    ),
    internetHandlingFees: cumulationPipe(
        paymentInfos,
        p => p.priceValues.internetHandlingFees
    ),
    gstPrice: cumulationPipe(paymentInfos, p => p.priceValues.gstPrice),
    totalPrice: cumulationPipe(paymentInfos, p => p.priceValues.totalPrice),
    totalPriceWOEarlyBird: cumulationPipe(
        paymentInfos,
        p => p.priceValues.totalPriceWOEarlyBird
    ),

    cgstExclusiveUnitPrice: cumulationPipe(
        paymentInfos,
        p => p.priceValues.cgstExclusiveUnitPrice
    ),
    creatorGstPrice: cumulationPipe(
        paymentInfos,
        p => p.priceValues.creatorGstPrice
    ),
});

/**
 * @deprecated use `callPaymentGateway` in `repoV2/utils/common/payment/callPaymentGateway.ts`
 */
export const callPaymentGateway =
    ({
        eventData,
        returnUrl,
        optionalCallbacks = {},
    }: {
        eventData?: IEvent.IEventData;
        returnUrl?: string | null;
        optionalCallbacks?: Record<string, Function>;
    }) =>
    ({ response }: Pick<IFetchDataAction.IReturnPayload, "response">) => {
        if (!isBrowser()) return {};

        const {
            message,
            status,
            data: {
                options,
                gateway,
                tid: transactionID,
                order,
                redirect_url: freeListingRedirectUrl = "", // Incase creator wants its users to be redirected to a specific url incase of the
                // free listings, then we redirect them to this url
                // freeListingRedirectUrl gets priority over returnUrl
                client_secret: clientSecret, // client_secret is needed for stripe

                response: internalResponse, // internalResponse is needed for tazapay
            },
        } = response;

        if (status !== 200) {
            throw new Error(message || "An error occurred. Please try again.");
        }
        if (!gateway) {
            throw new Error("Gateway not specified");
        }
        setSessionStorageItem(
            SESSION_STORAGE_KEYS.PAYMENT_STATUS_GATEWAY_EXISTS,
            true
        );
        setSessionStorageItem(
            SESSION_STORAGE_KEYS.PAYMENT_PAGE_REFERRER,
            window.location.href
        );
        switch (gateway.toLowerCase()) {
            case GATEWAYS_ENUM.RAZORPAY.toLowerCase():
                // eslint-disable-next-line no-case-declarations
                const rzp = new window.Razorpay({
                    ...options,
                    modal: {
                        ondismiss: () => {
                            optionalCallbacks?.onRazorpayClose?.();
                        },
                    },
                });
                rzp?.open();
                break;

            // This ensures that the Juspay API is loaded and calling the SDK doesn't throw an error
            case GATEWAYS_ENUM.NEW_JUSPAY.toLowerCase(): {
                // getting web as weblink to redirect coming from backend
                if (order?.web) window.location.href = order?.web;
                break;
            }

            // This ensures that the Tazapay API is loaded and calling the SDK doesn't throw an error
            case GATEWAYS_ENUM.TAZAPAY.toLowerCase(): {
                makePaymentViaTazapay(internalResponse);
                break;
            }

            case GATEWAYS_ENUM.STRIPE.toLowerCase():
                // This event trigger tells the stripe component to show itself and collect the user's card
                // TODO: Replace this with saga channels implementation
                // eslint-disable-next-line no-case-declarations
                makePaymentViaStripe(clientSecret);
                break;

            case GATEWAYS_ENUM.FREE.toLowerCase(): {
                const url =
                    freeListingRedirectUrl ||
                    appendURLSearchParams(
                        (returnUrl ||
                            getReturnUrl({
                                isDirectBooking: false,
                                eventId: eventData?.uuid,
                            }))!,
                        {
                            transaction: transactionID,
                            status: TRANSACTION_STATUSES.SUCCESS,
                        }
                    );
                window.open(url, "_self"); // TODO: Use Router instead here?
                break;
            }
            case GATEWAYS_ENUM.PAYPAL.toLowerCase():
                window.open(eventData?.paypal_link, "_blank");
                break;
            default:
                break;
        }
        return {};
    };

export const evaluatePaymentMethod = ({
    customerDetailsProp,
    hostData,
}: {
    customerDetailsProp: IUser.ICustomerDetailsProp;
    hostData?: IHost.IHostData;
}): string => {
    const { payment_method: userInputPaymentMethod } =
        customerDetailsProp?.[0] || {};

    if (hostData?.is_international_creator)
        return hostData?.allow_payment_method_toggle
            ? GATEWAYS_ENUM.PAYPAL
            : GATEWAYS_ENUM.STRIPE;

    return (
        userInputPaymentMethod ||
        getSessionStorageItem(STORAGE_KEYS.SELECTED_PAYMENT_GATEWAY) ||
        DEFAULT_PAYMENT_GATEWAY
    );
};

/**
 * @param hostData IHost.IHostData in reducer
 * @returns custom stripe key
 */
export const getCustomStripeKey = (hostData?: IHost.IHostData) => {
    if (!hostData) return undefined;
    const customStripeConfig =
        (
            (hostData.creator_gateway_details || []).find(
                i => i.gateway === GATEWAYS_ENUM.STRIPE
            ) || {}
        ).config || {};

    return customStripeConfig.published_key;
};
