import * as Sentry from "@sentry/nextjs";

type ISentryItem = ["sentry"] | ["sentry", { forceLogStacktrace?: boolean }];
type IConsoleItem = ["console"];
export const logError = async ({
    error,
    extraErrorData,
    occuredAt,
    when,
    severity = "medium",
    customErrorMessage,
    logToPlatforms = [["sentry"]],
}: {
    error?: any;
    extraErrorData?: any;
    occuredAt?: string;
    when?: string;
    severity?: "low" | "medium" | "critical" | "blocker";
    customErrorMessage?: string;
    logToPlatforms?: Array<IConsoleItem | ISentryItem>;
}) => {
    const customError = customErrorMessage || {
        error,
        occuredAt,
        when,
        severity,
        extraErrorData,
    };

    logToPlatforms.forEach(([platform, platformOptions]) => {
        if (
            platform.includes("console") ||
            process.env.NODE_ENV !== "production"
        ) {
            console.log(customError);
        }
        if (platform.includes("sentry")) {
            // do we want to log the stacktrace instead of logging the custom object?
            // https://docs.sentry.io/platforms/javascript/guides/nextjs/usage/#capturing-errors
            if (error instanceof Error && platformOptions?.forceLogStacktrace) {
                Sentry.captureException(error);
            } else {
                // convert the customError object to a string and then to an error object
                try {
                    const customErrorObject = new Error(
                        JSON.stringify(customError)
                    );
                    Sentry.captureException(customErrorObject);
                } catch (e) {
                    // if we can't convert the customError object to a string

                    // then log it to console
                    console.log(e);

                    // if we are in development mode, then throw the error
                    if (process.env.NODE_ENV !== "production") throw e;
                }
            }
        }
    });
};
