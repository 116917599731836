// import { FetchTypes } from "@Interfaces/redux/IUtils";
import { IFetchDataAction } from "@Redux/sagas/api";
import { getVisitorDetails } from "@Utils";
import { appendQueryParams } from "@Utils/urls";
import { ResponseType } from "axios";
import { CALL_API_METHODS } from "repoV2/constants/apis";
import { GET_USER_IP_DETAILS_API_URL } from "repoV2/utils/common/analytics/userIp";
import { AUTH_STATUS_CODES } from "repoV2/constants/user&auth&login/authCodes";
import { CURRENCY_STRINGS } from "repoV2/constants/payment";
import { isBrowser } from "../common";
import { ACTION_TYPES } from "./actions";
import { DEFAULT_FONT, DEFAULT_TEMPLATE_KEY } from "./keys";

// TODO: Moved here as a temporary measure to eliminate circular dependency
// const appendQueryParams = (
//     url: string,
//     params: { [key: string]: string | number | boolean | undefined }
// ) => {
//     let newUrl = url.indexOf("?") < 0 ? `${url}?` : url;
//     Object.entries(params).forEach(([key, value]) => {
//         if (value) newUrl += `&${key}=${value}`;
//     });
//     return newUrl;
// };
export namespace IAPICall {
    export interface IParams {
        url: string;
        baseURL?: string;
        method?: string;
        payload?: any;
        queryParams?: any;
        headers?: any;
        configOptions?: {
            disableAuthHeaders?: boolean;
            disableTimezoneHeaders?: boolean;
            shouldRelogin?: boolean;
            contentType?: string;
        };
        responseType?: ResponseType;
    }
}

export type FetchTypes =
    | "NOT_FETCHED"
    | "FETCHING"
    | "FETCHED"
    | "FETCH_ERROR"
    | "NOT_FOUND";

export const BASE_URL =
    process.env.NEXT_PUBLIC_API_URL || "https://api.myscoot.in";

// Types of fetch status supported
export const FETCH_STATUS = {
    NOT_FETCHED: "NOT_FETCHED" as FetchTypes,
    FETCHING: "FETCHING" as FetchTypes,
    FETCHED: "FETCHED" as FetchTypes,
    FETCH_ERROR: "FETCH_ERROR" as FetchTypes,
    NOT_FOUND: "NOT_FOUND" as FetchTypes,
};

// Unique identifiers for the API action types to keep the
// API call and the redux callback bundled together
export const API_ACTION_TYPES = {
    /*  Host API Actions */
    FETCH_HOST: "FETCH_HOST",
    // FETCH_THEME is deprecated. TODO: Remove this during API revamp.
    // FETCH_THEME: "FETCH_THEME",
    FETCH_UI_CUSTOMIZATION: "FETCH_UI_CUSTOMIZATION", // Fetch the data for a given theme, font, and/or template
    FETCH_HOST_THEME: "FETCH_HOST_THEME", // Fetch the data for the theme, font, and template assigned to that host
    SEND_EXIT_INTENT: "SEND_EXIT_INTENT",
    SEND_HOST_MESSAGE: "SEND_HOST_MESSAGE",
    FETCH_HOST_PLANS: "FETCH_HOST_PLANS",
    FETCH_PLAN_DETAILS: "FETCH_PLAN_DETAILS",
    FETCH_TEAM_DETAILS: "FETCH_TEAM_DETAILS",
    /*  Event API Actions */
    FETCH_EVENT: "FETCH_EVENT",
    GET_LOYALTY_DISCOUNT_LIST: "GET_LOYALTY_DISCOUNT_LIST",
    APPLY_DISCOUNT: "APPLY_DISCOUNT",
    PAYMENT_AUTH: "PAYMENT_AUTH",
    PAYMENT_AUTH_RENEW: "PAYMENT_AUTH_RENEW",
    PAYMENT_AVAILABILITY: "PAYMENT_AVAILABILITY",
    CART_PAYMENT_AVAILABILITY: "CART_PAYMENT_AVAILABILITY",
    PAYMENT_INITIATE: "PAYMENT_INITIATE",
    FETCH_ORDER_STATUS: "FETCH_ORDER_STATUS",
    FETCH_CREATOR_MISCELLANEOUS_DATA: "FETCH_CREATOR_MISCELLANEOUS_DATA",
    FETCH_LISTING_MISCELLANEOUS_DATA: "FETCH_LISTING_MISCELLANEOUS_DATA",
    FETCH_OFFERING_VARIATIONS: "FETCH_OFFERING_VARIATIONS",
    POST_ADDRESS: "POST_ADDRESS",
    FETCH_UPSELL: "FETCH_UPSELL",
    /*  Utils API Actions */
    CHECK_DOMAIN_EXISTENCY: "CHECK_DOMAIN_EXISTENCY",
    POST_ANALYTICS: "POST_ANALYTICS",
    /*  Recorded Content API Actions */
    VERIFY_BOOKING_EXISTENCE: "VERIFY_BOOKING_EXISTENCE", // Corresponds to CheckEmail,
    GOOGLE_AUTH_LOGIN: "GOOGLE_AUTH_LOGIN",
    FETCH_CONTENT_LIST: "FETCH_CONTENT_LIST",
    FETCH_CONTENT_DETAILS: "FETCH_CONTENT_DETAILS",
    FETCH_CONTENT_COMMENTS: "FETCH_CONTENT_COMMENTS",
    FETCH_CONTENT_COMMENT_REPLIES: "FETCH_CONTENT_COMMENT_REPLIES",
    FETCH_WATERMARK_TYPE: "FETCH_WATERMARK_TYPE",
    FETCH_WATERMARK_URL: "FETCH_WATERMARK_URL",
    CREATE_CONTENT_COMMENT: "CREATE_CONTENT_COMMENT",
    /*  Feedback API Actions */
    FETCH_FEEDBACK_QUESTIONS: "FETCH_FEEDBACK_QUESTIONS",
    CREATE_FEEDBACK: "CREATE_FEEDBACK",
    /*  User API Actions */
    FETCH_USER_IP: "FETCH_USER_IP", // Get the user's IP for analytics purposes\
    FETCH_USER_IP_FALLBACK: "FETCH_USER_IP_FALLBACK", // Get the user's IP from the fallback api
    FETCH_USER_IP_2ND_FALLBACK: "FETCH_USER_IP_2ND_FALLBACK", // Get the user's IP from the 2nd fallback api
    GENERATE_OTP: "GENERATE_OTP",
    VERIFY_OTP: "VERIFY_OTP", // Corresponds to GetUsername
    FETCH_TOKEN: "FETCH_TOKEN",
    FETCH_BOOKINGS_OF_SKU_TYPE: "FETCH_BOOKINGS_OF_SKU_TYPE",
    FETCH_PURCHASE_HISTORY: "FETCH_PURCHASE_HISTORY",
    FETCH_BLOG_POSTS: "FETCH_BLOG_POSTS",
    UPDATE_VISITOR_DETAILS: "UPDATE_VISITOR_DETAILS",
    UPDATE_BLOG_REACTION: "UPDATE_BLOG_REACTION",
    FETCH_BLOG_POST_DETAILS: "FETCH_BLOG_POST_DETAILS",
    FETCH_BLOG_POST_COMMENTS: "FETCH_BLOG_POST_COMMENTS",
    CREATE_BLOG_POST_COMMENT: "CREATE_BLOG_POST_COMMENT",
    FETCH_COMMENT_REPLIES: "FETCH_COMMENT_REPLIES",
    HANDLE_COMMENT_REACTION: "HANDLE_COMMENT_REACTION",
    SUBSCRIBE_TO_BLOG: "SUBSCRIBE_TO_BLOG",
    BLOG_POST_ANALYTICS: "BLOG_POST_ANALYTICS",
    UPDATE_FCM_TOKEN: "UPDATE_FCM_TOKEN", // update firebase cloud messaging token
    FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
    FETCH_NOTIFICATION_MESSAGE: "FETCH_NOTIFICATION_MESSAGE",
    FETCH_NEW_NOTIFICATION_STATUS: "FETCH_NEW_NOTIFICATION_STATUS",
    UPDATE_NEW_NOTIFICATION_STATUS: "UPDATE_NEW_NOTIFICATION_STATUS",
    JOIN_COMMUNITY: "JOIN_COMMUNITY",
    ADD_QUESTIONS: "ADD_QUESTIONS",
    GET_ANSWERS: "GET_ANSWERS",
    FETCH_REPAYMENT_DATA: "FETCH_REPAYMENT_DATA",

    /* My Schedule page apis */
    GET_SCHEDULABLE_BOOKED_SESSIONS: "GET_SCHEDULABLE_BOOKED_SESSIONS",
    GET_OPEN_APPOINTMENT_SLOTS_FOR_RESCHEDULING:
        "GET_OPEN_APPOINTMENT_SLOTS_FOR_RESCHEDULING",
    RESCHEDULE_APPOINTMENT_SESSION: "RESCHEDULE_APPOINTMENT_SESSION",
    SCHEDULE_APPOINTMENT_SESSION: "SCHEDULE_APPOINTMENT_SESSION",

    GET_TELEGRAM_NAME: "GET_TELEGRAM_NAME",
    /**
     * telegram gives tokenised auth data, we cant use outdated values stored at our backend
     * TODO: delete if not used till 1st Jan 2024
    GET_TELEGRAM_EXISTING_USER_DATA: "GET_TELEGRAM_EXISTING_USER_DATA",
     */

    /* My Bookings page apis */
    GET_EXLY_CONNECT_ZOOM_APP_MEETING_URL:
        "GET_EXLY_CONNECT_ZOOM_APP_MEETING_URL",

    /* My Meetings page apis */
    GET_EXLY_CONNECT_MEETING_DETAILS: "GET_EXLY_CONNECT_MEETING_DETAILS",
    GET_VDO_CIPHER_VIDEO_OTP: "GET_VDO_CIPHER_VIDEO_OTP",

    GET_OFFERING_URL_SLUG_DETAILS: "GET_OFFERING_URL_SLUG_DETAILS",

    GET_VARIANTS_DATA: "GET_VARIANTS_DATA",
    GET_PINCODE_DELIVERY_ESTIMATE: "GET_PINCODE_DELIVERY_ESTIMATE",
    GET_SHIPROCKET_TRACKING_URL: "GET_SHIPROCKET_TRACKING_URL",

    GET_TRANSACTION_DETAILS: "GET_TRANSACTION_DETAILS",

    GET_LISTING_DETAILS: "GET_LISTING_DETAILS",
    UPDATE_RECORDED_CONTENT_MARK: "UPDATE_RECORDED_CONTENT_MARK",
    MARK_RECORDED_CONTENT_LESSON_COMPLETE:
        "MARK_RECORDED_CONTENT_LESSON_COMPLETE",

    GET_RECORDED_CONTENT_CURRICULUM: "GET_RECORDED_CONTENT_CURRICULUM",
    GET_RECORDED_CONTENT_QUESTIONNAIRE: "GET_RECORDED_CONTENT_QUESTIONNAIRE",
    ADD_RECORDED_CONTENT_EXAM_ATTEMPT: "ADD_RECORDED_CONTENT_EXAM_ATTEMPT",
    SUBMIT_RECORDED_CONTENT_FORM_OR_EXAM:
        "SUBMIT_RECORDED_CONTENT_FORM_OR_EXAM",
    GET_RECORDED_CONTENT_FORM_RESPONSE: "GET_RECORDED_CONTENT_FORM_RESPONSE",
    GET_RECORDED_CONTENT_QUESTIONNAIRE_STATUS:
        "GET_RECORDED_CONTENT_QUESTIONNAIRE_STATUS",

    GET_OFFERING_CERTIFICATE_DETAILS: "GET_OFFERING_CERTIFICATE_DETAILS",
    GET_COURSE_CERTIFICATE_DETAILS: "GET_COURSE_CERTIFICATE_DETAILS", // for logged in user
    GET_OFFERING_TIMEZONE_RESTRICTION: "GET_OFFERING_TIMEZONE_RESTRICTION",
    GET_LIVE_SESSION_DETAILS: "GET_LIVE_SESSION_DETAILS",

    GET_VENUE_DETAILS: "GET_VENUE_DETAILS",
    ADD_RC_VISIT_COUNT: "ADD_RC_VISIT_COUNT",
};

interface IApiActions {
    [k: string]: {
        baseURL?: string;
        url: (k?: any) => any;
        method?: string;
        successAction?: string | Array<string>; // Redux action to call on success
        successStatus?: Array<number>; // The API status codes that are recognizerd as success
        errorAction?: string | Array<string>; // Redux action to call on error
        nextActionProps?: any; // Props to pass to the next action/callback
        disableAuthHeaders?: boolean;
        disableTimezoneHeaders?: boolean;
        shouldRelogin?: boolean;
        successCallback?: (k: IFetchDataAction.IReturnPayload) => void;
        errorCallback?: (k: IFetchDataAction.IReturnPayload) => void;
        shouldLogError?: (k: IFetchDataAction.IReturnPayload) => {
            log: boolean;
        }; // addany other thing if needed in future
    };
}

// TODO: Implement generic query param composer for GET param string
// Enum with the config for each api action type
export const API_ACTIONS: IApiActions = {
    /*  Host API Actions */
    [API_ACTION_TYPES.FETCH_HOST]: {
        url: ({
            hostName,
            minify = false, // Whether the listing collection data is needed or not
            utm_affiliate,
        }: {
            hostName: string;
            minify?: boolean;
            utm_affiliate?: string;
        }) =>
            appendQueryParams(`/host/view/${hostName}`, {
                is_ssr: !isBrowser(),
                minify: !!minify,
                utm_affiliate,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_DATA,
        errorAction: ACTION_TYPES.UTILS.REDIRECT_AWAY,
    },
    [API_ACTION_TYPES.FETCH_UI_CUSTOMIZATION]: {
        // All of these URL params are optional. API will return data only for the params sent,
        // but sending everything here to get default values in case only one thing is changed.
        url: ({
            themeName, // TODO: Ask the back-end to send the default theme for the given template
            templateName = DEFAULT_TEMPLATE_KEY,
            fontName = DEFAULT_FONT.slug,
        }: {
            themeName: string;
            templateName: string;
            fontName: string;
        }) =>
            appendQueryParams(`/host/ui/demo`, {
                template: templateName,
                theme: themeName,
                font: fontName,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_THEME_DATA,
    },
    [API_ACTION_TYPES.FETCH_HOST_THEME]: {
        url: ({ hostName }: { hostName: string }) =>
            `/host/theme/active/${hostName}`, // theme colors should be `colors || theme.color_codes`
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_THEME_DATA,
    },
    [API_ACTION_TYPES.FETCH_USER_IP]: {
        url: () => "",
        baseURL: "https://www.cloudflare.com/cdn-cgi/trace/",
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.UTILS.VALIDATE_IP,
        errorAction: ACTION_TYPES.UTILS.VALIDATE_IP,
        // TODO: make finallyAction param
        disableAuthHeaders: true,
        disableTimezoneHeaders: true,
    },
    [API_ACTION_TYPES.FETCH_USER_IP_FALLBACK]: {
        url: () => "",
        baseURL: GET_USER_IP_DETAILS_API_URL,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.UTILS.VALIDATE_IP_FALLBACK,
        errorAction: ACTION_TYPES.UTILS.VALIDATE_IP_FALLBACK,
        disableAuthHeaders: true,
        disableTimezoneHeaders: true,
    },
    [API_ACTION_TYPES.FETCH_USER_IP_2ND_FALLBACK]: {
        url: () => "",
        baseURL: "https://geolocation-db.com/json/",
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.UTILS.VALIDATE_IP_2ND_FALLBACK,
        errorAction: ACTION_TYPES.UTILS.VALIDATE_IP_2ND_FALLBACK,
        disableAuthHeaders: true,
        disableTimezoneHeaders: true,
    },
    [API_ACTION_TYPES.SEND_EXIT_INTENT]: {
        url: () => `/host/webpage/lead/create`,
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.SEND_HOST_MESSAGE]: {
        url: () => `/host/interest/create`,
        method: CALL_API_METHODS.POST,
        errorAction: ACTION_TYPES.UTILS.LOG_ERROR,
    },
    [API_ACTION_TYPES.FETCH_HOST_PLANS]: {
        url: ({ hostName }: { hostName: string }) => `/host/plans/${hostName}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_PLAN_LIST,
    },
    [API_ACTION_TYPES.FETCH_PLAN_DETAILS]: {
        url: ({ planId, subDomain }: { planId: string; subDomain: string }) =>
            `/host/dropdown/${subDomain}/${planId}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_PLAN_DETAILS,
    },
    [API_ACTION_TYPES.FETCH_TEAM_DETAILS]: {
        url: ({ hostName }: { hostName: string }) =>
            `/users/get/staff?sub_domain=${hostName}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_TEAM_DATA,
    },

    /*  Event API Actions */
    [API_ACTION_TYPES.FETCH_EVENT]: {
        url: ({
            eventId,
            dynamicLink,
            renewSubscription,
            hostName,
            community,
            thankyouPgId,
        }: {
            eventId: string;
            dynamicLink?: string;
            renewSubscription?: boolean;
            hostName?: string;
            community?: string;
            thankyouPgId?: string;
        }) =>
            // TODO: Update this before merging
            appendQueryParams(`/host/listing/${hostName}/${eventId}`, {
                is_ssr: !isBrowser(),
                dynamic_link_id: dynamicLink,
                is_renewal: renewSubscription,
                community_listing_id: community,
                thankyou_page_uuid: thankyouPgId,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.EVENT.UPDATE_DATA,
        errorAction: ACTION_TYPES.UTILS.LOG_ERROR,
    },
    [API_ACTION_TYPES.GET_LOYALTY_DISCOUNT_LIST]: {
        url: ({ customerEmail, eventId, currency = CURRENCY_STRINGS.INR }) =>
            appendQueryParams(`/payments/exly/discounts/loyalty`, {
                customer_email: customerEmail,
                listing_uid: eventId,
                currency,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.EVENT.UPDATE_LOYALTY_DISCOUNT_LIST,
        errorAction: ACTION_TYPES.EVENT.UPDATE_LOYALTY_DISCOUNT_LIST,
    },
    [API_ACTION_TYPES.APPLY_DISCOUNT]: {
        url: () => `/payments/exly/discounts/apply`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.EVENT.APPLY_DISCOUNT,
        errorAction: ACTION_TYPES.EVENT.APPLY_DISCOUNT_ERROR,
        shouldLogError: (args: any) => {
            // const { apiCallArgs, metadata, response } = args
            const response = args?.response;
            if (
                response?.status === 400 &&
                response?.message === "Invalid Coupon Code"
            ) {
                return { log: false };
            }
            return { log: true };
        },
    },
    [API_ACTION_TYPES.FETCH_UPSELL]: {
        url: ({
            listingID,
            upsellType,
        }: {
            listingID: string;
            upsellType: number;
        }) =>
            appendQueryParams(`/host/upsell/${listingID}`, {
                upsell_type: upsellType,
            }),
        successAction: ACTION_TYPES.EVENT.UPDATE_UPSELL_LISTINGS,
        method: CALL_API_METHODS.GET,
    },

    [API_ACTION_TYPES.PAYMENT_AUTH]: {
        url: () => `/users/exly/multiple/login`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.USER.UPDATE_USERNAME,
        errorAction: ACTION_TYPES.UTILS.SHOW_ALERT,
    },

    [API_ACTION_TYPES.PAYMENT_AUTH_RENEW]: {
        // used for rebooking flow
        url: () => `/users/exly/renew/login`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.USER.UPDATE_RENEW_USERNAME,
        errorAction: ACTION_TYPES.UTILS.SHOW_ALERT,
    },

    [API_ACTION_TYPES.PAYMENT_AVAILABILITY]: {
        url: () => "/host/listing/availability",
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.EVENT.UPDATE_AVAILABILITY,
        errorCallback: callbackParam => {
            const response = callbackParam?.response;
            const metadata = callbackParam?.metadata;
            if (metadata?.paymentAvailabilityErrorCallback)
                metadata.paymentAvailabilityErrorCallback(response);
        },
    },

    [API_ACTION_TYPES.CART_PAYMENT_AVAILABILITY]: {
        url: () => "/host/listing/availability",
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.CART.UPDATE_AVAILABILITY,
        errorCallback: callbackParam => {
            const response = callbackParam?.response;
            const defaultAlertMessage =
                "An error has occurred. Please try again.";
            const alertMessage = response?.message;

            alert(alertMessage || defaultAlertMessage);
        },
    },

    [API_ACTION_TYPES.PAYMENT_INITIATE]: {
        url: () => `/payments/order/initiate`,
        method: CALL_API_METHODS.POST,
        errorAction: ACTION_TYPES.UTILS.SHOW_ALERT,
    },
    [API_ACTION_TYPES.FETCH_LISTING_MISCELLANEOUS_DATA]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("/host/listing/miscellaneous/data", {
                listing__uuid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.EVENT.SET_LISTING_MISCELLANEOUS_DATA,
    },
    [API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA]: {
        url: ({ hostName, type }: { hostName: string; type: number }) =>
            appendQueryParams(`/users/miscellaneous/data`, {
                user__sub_domain__iexact: hostName,
                type,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.FETCH_ORDER_STATUS]: {
        url: ({ transactionId }: { transactionId: string }) =>
            `/payments/exly/status?order_uid=${transactionId}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.USER.UPDATE_ORDER_DETAILS,
    },
    [API_ACTION_TYPES.POST_ANALYTICS]: {
        url: () => `/analytics/event/post`,
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.GET_ANSWERS]: {
        url: ({ transaction_uid, username }) =>
            appendQueryParams(`/payments/exly/answers`, {
                order_uid: transaction_uid,
                username,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.ADD_QUESTIONS]: {
        url: () => `/payments/exly/answers/add`,
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.POST_ADDRESS]: {
        url: () => `/payments/exly/address/update`,
        method: CALL_API_METHODS.POST,
    },

    /* Feedback Flow Actions */
    [API_ACTION_TYPES.FETCH_FEEDBACK_QUESTIONS]: {
        url: ({ eventId }: { eventId: string }) =>
            appendQueryParams(`/users/exly/feedback/questions`, {
                listing_uuid: eventId,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.CREATE_FEEDBACK]: {
        url: () => "/users/exly/feedback/insert",
        method: CALL_API_METHODS.POST,
    },

    /* Recorded Content Actions */
    [API_ACTION_TYPES.FETCH_CONTENT_LIST]: {
        url: ({ hostName }: { hostName: string }) =>
            `/users/recordedcontent/list?sub_domain=${hostName}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_CONTENT_LIST,
    },
    [API_ACTION_TYPES.FETCH_CONTENT_DETAILS]: {
        url: ({
            listingId,
            replyId,
        }: // If a specific replyId is mentioned, it is an indication
        // that the recorded content has been opened by an email URL,
        // so a specific version of the content needs to be loaded
        // instead of the latest version. Providing the API with the replyId
        // ensures that the back-end loads the content version that corresponds
        // to the end user's version of recorded content where the comment was made
        {
            listingId: string;
            replyId?: string;
        }) =>
            appendQueryParams(`/users/recordedcontent/details`, {
                listing_uuid: listingId,
                reply_id: replyId,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_CONTENT_DETAILS,
        shouldRelogin: true,
    },
    [API_ACTION_TYPES.FETCH_CONTENT_COMMENTS]: {
        url: ({
            subCategoryUuid,
            page,
            source,
            questionnaireUuids,
        }: {
            subCategoryUuid: string;
            page: number;
            source: number;
            questionnaireUuids: string;
        }) =>
            appendQueryParams("/host/recorded/comments/list", {
                sub_category__uuid: subCategoryUuid,
                page,
                source,
                exam_submissions: questionnaireUuids,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_CONTENT_COMMENTS,
        shouldRelogin: true,
    },
    [API_ACTION_TYPES.FETCH_CONTENT_COMMENT_REPLIES]: {
        url: ({ commentId }: { commentId: number }) =>
            `host/replies/listing?comment_uid=${commentId}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_COMMENT_REPLIES,
        shouldRelogin: true,
    },
    [API_ACTION_TYPES.FETCH_WATERMARK_TYPE]: {
        url: ({ listingId }: { listingId: string }) =>
            `host/fetch/marking/${listingId}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_WATERMARK_TYPE,
    },
    [API_ACTION_TYPES.FETCH_WATERMARK_URL]: {
        url: () => `host/fetch/watermark/url`,
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.CREATE_CONTENT_COMMENT]: {
        url: () => "/host/recorded/comments/add",
        method: CALL_API_METHODS.POST,
        successStatus: [200, 201],
        shouldRelogin: true,
    },

    /* User Authentication Actions */
    [API_ACTION_TYPES.VERIFY_BOOKING_EXISTENCE]: {
        url: () => `/users/booking/existency`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.USER.UPDATE_EMAIL,
    },
    [API_ACTION_TYPES.GENERATE_OTP]: {
        url: () => `/users/generate/otp`,
        method: CALL_API_METHODS.POST,
        // successAction: ACTION_TYPES.USER.UPDATE_RECORDED_CONTENT,
        errorAction: ACTION_TYPES.UTILS.SHOW_ALERT,
    },
    [API_ACTION_TYPES.VERIFY_OTP]: {
        url: () => `/users/exly/customer/login`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.USER.UPDATE_USERNAME,
        errorAction: ACTION_TYPES.UTILS.SHOW_ALERT,
    },
    [API_ACTION_TYPES.GOOGLE_AUTH_LOGIN]: {
        url: () => `/users/auth`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.USER.UPDATE_EMAIL,
    },
    [API_ACTION_TYPES.FETCH_TOKEN]: {
        url: () => `/users/auth/token`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.USER.UPDATE_AUTH_TOKEN,
    },
    [API_ACTION_TYPES.FETCH_PURCHASE_HISTORY]: {
        url: ({ page }: { page: number }) =>
            `host/customer/purchase/history?page=${page}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.USER.SET_PURCHASE_HISTORY,
    },

    /* Blog Actions */
    [API_ACTION_TYPES.FETCH_BLOG_POSTS]: {
        url: ({ page, hostName }: { page: number; hostName: string }) =>
            appendQueryParams(`blogs/public/posts/`, {
                sub_domain: hostName,
                visitor_uuid: getVisitorDetails().uuid,
                page,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_BLOG_POSTS,
    },
    [API_ACTION_TYPES.UPDATE_VISITOR_DETAILS]: {
        url: () => "blogs/public/visitors/create/",
        method: CALL_API_METHODS.POST,
        successStatus: [201],
    },
    [API_ACTION_TYPES.UPDATE_BLOG_REACTION]: {
        url: ({ blogPostUuid }: { blogPostUuid: string }) =>
            `blogs/public/posts/${blogPostUuid}/react/`,
        method: CALL_API_METHODS.POST,
        successStatus: [201],
    },
    [API_ACTION_TYPES.FETCH_BLOG_POST_DETAILS]: {
        url: ({
            blogPostUuid,
            hostName,
            preview,
        }: {
            blogPostUuid: string;
            hostName: string;
            preview: boolean;
        }) =>
            appendQueryParams(`blogs/public/posts/${blogPostUuid}`, {
                sub_domain: hostName,
                visitor_uuid: getVisitorDetails().uuid,
                preview,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_BLOG_POST_DETAILS,
    },
    [API_ACTION_TYPES.FETCH_BLOG_POST_COMMENTS]: {
        url: ({ blogPostUuid, page }: { blogPostUuid: string; page: number }) =>
            `blogs/public/posts/comments?post_id=${blogPostUuid}&page=${page}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_BLOG_POST_COMMENTS,
    },
    [API_ACTION_TYPES.FETCH_COMMENT_REPLIES]: {
        url: ({ commentId }: { commentId: number }) =>
            `blogs/public/comments/${commentId}/replies/`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_COMMENT_REPLIES,
        shouldRelogin: true,
    },
    [API_ACTION_TYPES.HANDLE_COMMENT_REACTION]: {
        url: () => `host/reaction/create`,
        method: CALL_API_METHODS.POST,
        successStatus: [201, 200],
    },
    [API_ACTION_TYPES.CREATE_BLOG_POST_COMMENT]: {
        url: () => `blogs/public/posts/comments/`,
        method: CALL_API_METHODS.POST,
        successStatus: [201, 200],
    },
    [API_ACTION_TYPES.SUBSCRIBE_TO_BLOG]: {
        url: ({ hostName }: { hostName: string }) =>
            appendQueryParams("blogs/public/subscribe/", {
                sub_domain: hostName,
            }),
        method: CALL_API_METHODS.POST,
        successStatus: [201],
        successAction: ACTION_TYPES.HOST.UPDATE_BLOG_SUBSCRIPTION_EMAIL,
    },
    [API_ACTION_TYPES.BLOG_POST_ANALYTICS]: {
        url: ({ hostName }: { hostName: string }) =>
            appendQueryParams("blogs/public/analytics/", {
                sub_domain: hostName,
            }),
        method: CALL_API_METHODS.POST,
        successStatus: [201],
    },

    /* FCM Notifications Actions */
    [API_ACTION_TYPES.UPDATE_FCM_TOKEN]: {
        url: () => "users/customer/tokens/update",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.FETCH_NOTIFICATIONS]: {
        url: ({ page }: { page: number }) =>
            `host/customer/notifications?page=${page}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.USER.UPDATE_NOTIFICATIONS,
        shouldLogError: (args: any) => {
            // const { apiCallArgs, metadata, response } = args
            const response = args?.response;
            if (response?.status === AUTH_STATUS_CODES.ACCESS_TOKEN_EXPIRED) {
                return { log: false };
            }
            return { log: true };
        },
    },
    [API_ACTION_TYPES.FETCH_NOTIFICATION_MESSAGE]: {
        url: ({ custom_mail_id }) =>
            appendQueryParams("notifications/email/fetchbyId", {
                custom_mail_id,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.FETCH_NEW_NOTIFICATION_STATUS]: {
        url: () => "users/customer/notification/check",
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.UPDATE_NEW_NOTIFICATION_STATUS]: {
        url: () => "users/customer/notification/seen",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.JOIN_COMMUNITY]: {
        url: ({ listingId }) => `content/community/join/${listingId}`,
        method: CALL_API_METHODS.POST,
        successAction: ACTION_TYPES.UTILS.UPDATE_COMMUNITY_APP_DATA,
    },
    [API_ACTION_TYPES.FETCH_REPAYMENT_DATA]: {
        url: ({
            paymentAction,
            orderId,
        }: {
            paymentAction: "rebook" | "edit";
            orderId: string;
        }) => `payments/order/action/${paymentAction}?order_uid=${orderId}`,
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_SCHEDULABLE_BOOKED_SESSIONS]: {
        url: ({ listingUuid, page }: { listingUuid: string; page?: string }) =>
            appendQueryParams("host/customer/booked/sessions", {
                listing_uuid: listingUuid,
                page,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_OPEN_APPOINTMENT_SLOTS_FOR_RESCHEDULING]: {
        url: ({
            listingUuid,
            page,
            dateRangeStart,
            dateRangeEnd,
        }: {
            listingUuid: string;
            page?: string;
            dateRangeStart: string;
            dateRangeEnd: string;
        }) =>
            appendQueryParams("host/open/sessions", {
                listing_uuid: listingUuid,
                page,
                start_datetime__start_range: dateRangeStart,
                start_datetime__end_range: dateRangeEnd,
            }),
        method: CALL_API_METHODS.GET,
    },

    [API_ACTION_TYPES.RESCHEDULE_APPOINTMENT_SESSION]: {
        url: () => "host/reschedule/customer/session",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.SCHEDULE_APPOINTMENT_SESSION]: {
        url: () => "host/schedule/customer/session",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.GET_TELEGRAM_NAME]: {
        url: ({ listingUid }: { listingUid: string }) =>
            `host/listing/telegram/${listingUid}`,
        method: CALL_API_METHODS.GET,
    },
    /**
     * telegram gives tokenised auth data, we cant use outdated values stored at our backend
     * TODO: delete if not used till 1st Jan 2024
    [API_ACTION_TYPES.GET_TELEGRAM_EXISTING_USER_DATA]: {
        url: ({
            countryCode,
            phoneNum,
        }: {
            countryCode: string;
            phoneNum: string;
        }) =>
            appendQueryParams("host/telegram/userexists", {
                country_code: countryCode,
                phone_number: phoneNum,
            }),
    },
     */
    [API_ACTION_TYPES.GET_EXLY_CONNECT_ZOOM_APP_MEETING_URL]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("host/fetch/exlycon/url", {
                listing_uuid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_EXLY_CONNECT_MEETING_DETAILS]: {
        url: ({ meetingUuid }: { meetingUuid: string }) =>
            appendQueryParams("host/exlycon/meeting/details", {
                meeting_uuid: meetingUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_VDO_CIPHER_VIDEO_OTP]: {
        url: () => "host/fetch/drm/otp",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.UPDATE_RECORDED_CONTENT_MARK]: {
        url: () => `users/recordedcontent/mark`,
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.GET_OFFERING_URL_SLUG_DETAILS]: {
        url: ({ hostName, slug }: { hostName: string; slug: string }) =>
            `marketing/seo/slug/${hostName}/${slug}`,
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_VARIANTS_DATA]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("host/booking/variants", {
                listing_uuid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_PINCODE_DELIVERY_ESTIMATE]: {
        url: ({
            listingUuid,
            variationUuid,
            pincode,
            currency,
            isInternationalEnabled,
        }: {
            listingUuid: string;
            variationUuid?: string;
            pincode?: string;
            currency?: string;
            isInternationalEnabled: boolean;
        }) =>
            appendQueryParams("users/shiprocket/pincode/check", {
                listing_uid: listingUuid,
                variation_uid: variationUuid,
                client_postcode: pincode,
                currency,
                is_international_enabled: isInternationalEnabled,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_SHIPROCKET_TRACKING_URL]: {
        url: ({ transactionUuid }: { transactionUuid: string }) =>
            `users/shiprocket/track/${transactionUuid}`,
        method: CALL_API_METHODS.GET,
    },

    // this api is authenticated and is meant for CT, do not use this
    [API_ACTION_TYPES.GET_LISTING_DETAILS]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            `host/listing/details/${listingUuid}`,
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_TRANSACTION_DETAILS]: {
        url: ({ transactionUuid }: { transactionUuid: string }) =>
            `payments/exly/transaction/detail/${transactionUuid}`,
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_RECORDED_CONTENT_CURRICULUM]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("host/recorded/curriculum", {
                listing_uuid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_COURSE_CERTIFICATE_DETAILS]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("host/content/certificate", {
                listing_uuid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_CONTENT_CERTIFICATE,
        shouldRelogin: true,
    },
    [API_ACTION_TYPES.GET_OFFERING_CERTIFICATE_DETAILS]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            `host/certificate/details/${listingUuid}`,
        method: CALL_API_METHODS.GET,
        successAction: ACTION_TYPES.HOST.UPDATE_CONTENT_CERTIFICATE,
    },
    [API_ACTION_TYPES.GET_OFFERING_TIMEZONE_RESTRICTION]: {
        url: ({ listingUuid, ...urlParams }: { [key: string]: string }) =>
            appendQueryParams("host/listing/region/accessible", urlParams),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_VENUE_DETAILS]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("host/listing/venuedetails", {
                listing_uid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_LIVE_SESSION_DETAILS]: {
        url: ({ contentUuid }: { contentUuid: string }) =>
            appendQueryParams("users/recordedcontent/livesession/details", {
                content_uuid: contentUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_RECORDED_CONTENT_QUESTIONNAIRE]: {
        url: ({ questionnaireUuid }: { questionnaireUuid: string }) =>
            appendQueryParams("host/content/questionairre/get", {
                questionairre_uid: questionnaireUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.ADD_RECORDED_CONTENT_EXAM_ATTEMPT]: {
        url: () => "host/content/attempt/create",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.SUBMIT_RECORDED_CONTENT_FORM_OR_EXAM]: {
        url: () => "host/content/attempt/submit",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.GET_RECORDED_CONTENT_FORM_RESPONSE]: {
        url: ({
            username,
            questionnaireUid,
            mediaContentId,
        }: {
            username: string;
            questionnaireUid: string;
            mediaContentId: string;
        }) =>
            appendQueryParams("host/recorded/form/attempt/responses", {
                username,
                questionairre_uid: questionnaireUid,
                recorded_content_uuid: mediaContentId,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_RECORDED_CONTENT_QUESTIONNAIRE_STATUS]: {
        url: ({
            username,
            questionnaireUid,
            mediaContentId,
        }: {
            username: string;
            questionnaireUid: string;
            mediaContentId: string;
        }) =>
            appendQueryParams("host/recorded/customer/questionairre/status", {
                username,
                questionairre_uid: questionnaireUid,
                recorded_content_uuid: mediaContentId,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.MARK_RECORDED_CONTENT_LESSON_COMPLETE]: {
        url: () => "users/recordedsubcategory/mark",
        method: CALL_API_METHODS.POST,
    },
    [API_ACTION_TYPES.GET_OFFERING_TIMEZONE_RESTRICTION]: {
        url: ({ listingUuid, ...urlParams }: { [key: string]: string }) =>
            appendQueryParams("host/listing/region/accessible", urlParams),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.GET_VENUE_DETAILS]: {
        url: ({ listingUuid }: { listingUuid: string }) =>
            appendQueryParams("host/listing/venuedetails", {
                listing_uid: listingUuid,
            }),
        method: CALL_API_METHODS.GET,
    },
    [API_ACTION_TYPES.ADD_RC_VISIT_COUNT]: {
        url: ({
            listingUuid,
            lessonUuid,
        }: {
            listingUuid: string;
            lessonUuid: string;
        }) =>
            appendQueryParams("host/recorded/lesson/view", {
                listing_uuid: listingUuid,
                lesson_uuid: lessonUuid,
            }),
        method: CALL_API_METHODS.POST,
    },
};
