import { isEmpty } from "@Utils";
import {
    COUNTRY_CODE_DROPDOWN_OPTIONS,
    getCountryCodeDropdownOptionFromKey,
} from "utils/location";
import { getEmailHasValidTopLevelDomain } from "repoV2/utils/common/validators/validators";
import { EMAIL_REGEX } from "repoV2/utils/common/validators/regex.constants";
import { digitsRegex, fullNameRegex } from ".";

export const INPUT_TYPES = {
    EMAIL: "email",
    PHONE_NUMBER: "phone_number",
};

export const FORM_FIELD_PROPS_FALLBACK = {
    value: "",
    inputType: "text",
    showError: false,
    editingDisabled: false,
    initialValue: "",
    validate: () => "",
    hidden: () => false,
    mandatory: () => true,
    processInput: (value: string) => value,
};

export const FORM_FIELDS_KEYS = {
    full_name: "full_name",
    email: "email",
    phone: "phone",
    country: "country",
    country_code: "country_code",
    address: "customer_address",
    message: "message",
    answers: "answers",
    gst_name: "gst_name",
    gst_number: "gst_number",
    billing_state: "billing_state",
};
export const FORM_FIELDS: {
    [k: string]: {
        key: string;
        inputType: "text" | "email" | "tel" | "textarea" | "select";
        initialValue?: string;
        validate?: (value: string, extraData?: { [k: string]: any }) => string;
        hidden?: (value?: string, extraData?: { [k: string]: any }) => boolean;
        mandatory?: (
            value?: string,
            extraData?: { [k: string]: any }
        ) => boolean;
        processInput?: (
            value: string,
            extraData?: { [k: string]: any }
        ) => string;
        [k: string]: any; // for storing any other field property like label, placeholder
    };
} = {
    [FORM_FIELDS_KEYS.full_name]: {
        key: FORM_FIELDS_KEYS.full_name,
        inputType: "text",
        validate: (value: string) => {
            if (isEmpty(value)) return "Please enter a name";
            if (!fullNameRegex.test(value.trim()))
                return "Please enter a valid name";
            return "";
        },
    },
    [FORM_FIELDS_KEYS.email]: {
        key: FORM_FIELDS_KEYS.email,
        inputType: "email",
        validate: (value: string) => {
            if (isEmpty(value)) return "Please enter an email";

            if (
                !EMAIL_REGEX.test(value) ||
                !getEmailHasValidTopLevelDomain(value)
            )
                return "Please enter a valid email";
            return "";
        },
    },
    [FORM_FIELDS_KEYS.phone]: {
        key: FORM_FIELDS_KEYS.phone,
        inputType: "tel",
        validate: (value: string, extraData?: { [k: string]: any }) => {
            if (extraData?.isInternationalCreator && isEmpty(value)) return "";
            if (!extraData?.isInternationalCreator && isEmpty(value))
                return "Please enter your phone number";
            if (!digitsRegex.test(value)) return "Enter a valid Phone number";
            if (extraData?.isInIndia) {
                if (value.length !== 10) {
                    return "Phone number should be 10 digits long";
                }
            } else {
                if (value.length < 4) return "Phone number too short";
                if (value.length > 16) return "Phone number too long";
            }
            return "";
        },
        processInput: (value: string, extraData?: { [k: string]: any }) => {
            const newValue = value.replace(/[^0-9.]/g, "");
            let inputSize = 16;
            if (extraData?.isInIndia) {
                inputSize = 10;
            }
            return newValue.slice(0, inputSize);
        },
        mandatory: (value, extraData?: { [k: string]: any }) => {
            if (extraData?.isInternationalCreator) {
                return false;
            }
            return true;
        },
    },
    [FORM_FIELDS_KEYS.country_code]: {
        key: FORM_FIELDS_KEYS.country_code,
        inputType: "select",
        options: COUNTRY_CODE_DROPDOWN_OPTIONS,
        initialValue: getCountryCodeDropdownOptionFromKey("IN").dial_code,
    },
    [FORM_FIELDS_KEYS.country]: {
        key: FORM_FIELDS_KEYS.country,
        inputType: "select",
        hidden: () => true,
        initialValue: getCountryCodeDropdownOptionFromKey("IN").name,
    },
    [FORM_FIELDS_KEYS.address]: {
        key: FORM_FIELDS_KEYS.address,
        inputType: "textarea",
    },
    [FORM_FIELDS_KEYS.message]: {
        key: FORM_FIELDS_KEYS.message,
        inputType: "textarea",
        validate: (value: string) => {
            if (isEmpty(value)) return "Please enter a message";
            if (isEmpty(value.trim())) return "Please enter a valid message";
            return "";
        },
    },
    [FORM_FIELDS_KEYS.gst_number]: {
        key: FORM_FIELDS_KEYS.gst_number,
        inputType: "text",
    },
    [FORM_FIELDS_KEYS.gst_name]: {
        key: FORM_FIELDS_KEYS.gst_name,
        inputType: "text",
    },
    [FORM_FIELDS_KEYS.billing_state]: {
        key: FORM_FIELDS_KEYS.billing_state,
        inputType: "select",
    },
};

// for data that might not be used in every form but is still universal
export const FORM_FIELDS_EXTRAS = {
    [FORM_FIELDS_KEYS.full_name]: {
        label: "Name",
        analyticsEvent: "typeName",
    },
    [FORM_FIELDS_KEYS.email]: {
        label: "Email",
        analyticsEvent: "typeEmail",
    },
    [FORM_FIELDS_KEYS.phone]: {
        label: "Phone",
        analyticsEvent: "typePhone",
    },
    [FORM_FIELDS_KEYS.country_code]: {
        label: "Country",
        analyticsEvent: "selectCountryCode",
    },
    [FORM_FIELDS_KEYS.country]: {
        label: "Country",
        analyticsEvent: "typeAddress",
    },
    [FORM_FIELDS_KEYS.address]: {
        label: "Address",
        analyticsEvent: "typeAddress",
    },
    [FORM_FIELDS_KEYS.message]: {
        label: "Message",
        analyticsEvent: "typeMessage",
    },
    [FORM_FIELDS_KEYS.gst_number]: {
        label: "GSTIN",
        analyticsEvent: "typeGstNumber",
    },
    [FORM_FIELDS_KEYS.gst_name]: {
        label: "Registered Company Name",
        analyticsEvent: "typeGstName",
    },
    [FORM_FIELDS_KEYS.billing_state]: {
        label: "State",
        analyticsEvent: "typeBillingState",
    },
};
