import {
    SELECTORS,
    useNotifications,
    useOnClickOutsideContainer,
    useUserLogin,
    setCssVar,
} from "@Utils";
import React, { useRef, useState, useEffect } from "react";
import { useRouter } from "next/router";
import { NotificationMessagePopup } from "@Modules/common";
import { useSelector } from "react-redux";
import { INavbar } from "./INavbar";
import { INotificationMessagePopup } from "../Popups/NotificationMessagePopup/INotificationMessagePopup";
import { INotification } from "./components/Notification/INotification";
import { getNavbarInternalProps } from "./data";

export const Navbar = ({
    /*
    TODO: move all business logic from every NavbarTemplate to this component
    or if it is just data, move it to utils/componentProps/navbar.ts.
    NavbarTemplates should just render.
    */
    preview,
    template,
    handlePreviewPopup,
    onGetInTouchClick,
    hostData,

    // parent component specific props
    hideMobHamMenu,
    showNotificationComponent: showNotificationComponentProp,
    templateRootRef,
}: INavbar.IProps): JSX.Element => {
    const host = useSelector(SELECTORS.host);
    const { logoutUser, isLoggedIn } = useUserLogin();
    const router = useRouter();

    const [isMobHamMenuOpen, setIsMobHamMenuOpen] = useState(false);
    const [isOfferingsDropdownOpen, setIsOfferingsDropdownOpen] =
        useState(false);
    const onMobHamMenuClick = () => {
        setIsMobHamMenuOpen(!isMobHamMenuOpen);
        setIsOfferingsDropdownOpen(false);
    };

    let loggedInState: INavbar.ILoggedInState;
    if (hostData?.hide_login_navbar) {
        loggedInState = "hidden";
    } else {
        loggedInState = isLoggedIn ? "loggedIn" : "loggedOut";
    }
    const showNotificationComponent =
        showNotificationComponentProp && loggedInState === "loggedIn";
    const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
    const [showNotificationMessagePopup, setShowNotificationMessagePopup] =
        useState(false);
    const [notificationMessagePopupData, setNotificationMessagePopupData] =
        useState<INotificationMessagePopup.IProps["data"]>(undefined);
    const {
        fetchNotificationMessage,
        newNotificationReceived,
        onOpenNotifications,
    } = useNotifications({
        fetchNotificationOnMount: showNotificationComponent,
        fetchNotificationOnNewNotificationReceived: showNotificationComponent,
    });
    const notificationContainerRef = useRef<HTMLDivElement>(null);
    const openNotificationsPanel = () => {
        // delay added so that this gets executed after
        // the onClickOutside call(which closes the panel)
        if (!showNotificationsPanel) {
            setTimeout(() => {
                setShowNotificationsPanel(true);
                onOpenNotifications();
            }, 0);
        }
    };
    const closeNotificationsPanel = () => {
        setShowNotificationsPanel(false);
    };
    useOnClickOutsideContainer(notificationContainerRef, () => {
        closeNotificationsPanel();
    });

    const { navbarItems, currentPathIsHostPage, NavbarTemplate, accountProps } =
        getNavbarInternalProps({
            host,
            hostData,
            setIsMobHamMenuOpen,
            isOfferingsDropdownOpen,
            setIsOfferingsDropdownOpen,
            router,
            preview,
            handlePreviewPopup,
            logoutUser,
            template,
            loggedInState,
            onGetInTouchClick,
        });

    const openNotificationMessagePopup = (customMaiId: number) => {
        setShowNotificationsPanel(false);
        setShowNotificationMessagePopup(true);

        fetchNotificationMessage(customMaiId, (apiData: any) => {
            setNotificationMessagePopupData(apiData?.response?.data);
        });
    };

    const notificationProps: INotification.IProps = {
        hideComponent: !showNotificationComponent,
        template,
        showNotificationsPanel,
        openNotificationsPanel,
        closeNotificationsPanel,
        newNotificationReceived,
        notificationContainerRef,
        openNotificationMessagePopup,
    };

    // Ref to the root element of the navbar
    const rootElement = useRef<HTMLDivElement>(null);

    // This will ensure that the css variable --host-page-navbar-height
    // will always have the updated height of the navbar, irrespective
    // of the device, template, or content, to ensure proper sizing for
    // the elements below
    useEffect(() => {
        const setHeightFn = () => {
            const height = rootElement.current?.clientHeight;
            setCssVar("--host-page-navbar-height", `${height}px`);
        };
        if (rootElement.current) {
            window.addEventListener("resize", setHeightFn);
        }
        return () => {
            window.removeEventListener("resize", setHeightFn);
        };
    }, [rootElement.current]);

    const props: INavbar.IChildProps = {
        // parent component specific props
        hideMobHamMenu,
        setIsMobHamMenuOpen,

        // props generated here
        isMobHamMenuOpen,
        isOfferingsDropdownOpen,
        onMobHamMenuClick,
        loggedInState,
        notificationProps,

        // props migrated to src/components/common/Navbar/data.ts
        navbarItems,
        accountProps,
        currentPathIsHostPage,

        rootElement,
        templateRootRef,
    };

    return (
        <>
            <NavbarTemplate {...props} />
            {showNotificationMessagePopup ? (
                <NotificationMessagePopup
                    data={notificationMessagePopupData}
                    closePopup={() => {
                        setShowNotificationMessagePopup(false);
                        setNotificationMessagePopupData(undefined);
                    }}
                />
            ) : null}
        </>
    );
};
