import { IHost } from "@Interfaces";

export const processCustomQuestions = (
    questions: Array<IHost.IQuestion>,
    answers: { [uuid: string]: string | string[] }
) => {
    return Object.entries(answers).map(([ques, ans]) => {
        /**
         * tags: #dev
         * * This is how custom processing for specific questions can be set up:
         * const question = questions.find(q => q.uuid === ques);
         * if (question?.type === CUSTOM_QUESTION_TYPES.CALENDAR) {
         *     return {
         *         ques,
         *         ans: transformToDesiredOutput(ans),
         *     };
         * }
         */
        return {
            ques,
            ans: Array.isArray(ans) ? ans.map(val => val.trim()) : ans.trim(),
        };
    });
};
