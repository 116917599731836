import React, { useState } from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { isEmpty, CUSTOM_QUESTIONS } from "@Utils";
import { DropdownSelector } from "@Modules/common/Form";
import { IDropdownSelector } from "@Modules/common/Form/FormInputs/DropdownSelector/IDropdownSelector";
import { ICustomQuestions } from "../ICustomQuestions";
import styles from "../../../userDetails.module.scss";

// If an option has the following prefix, that is an indication to
// show the "Other" option where a custom input is to be accepted

const SingleSelectQuestion = ({
    htmlId,
    item,
    showInputError,
    userInput,
    answers,
    setAnswers,
    maxMenuHeight = "auto",
}: ICustomQuestions.ISingleSelectQuestionProps) => {
    const [isOtherSelected, setOtherSelected] = useState<boolean>(false);

    const castedUserInput = ((userInput as string) || "").trim();

    const dropdownValueChange = (
        e:
            | IDropdownSelector.Option
            | ICustomQuestions.ISingleSelectOption
            | null
    ) => {
        if (!e?.inputName) return;

        setOtherSelected(e?.showOther);

        setAnswers({
            ...answers,
            [e?.inputName]: e?.showOther ? "" : e?.value,
        });
    };

    const setCustomValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswers({ ...answers, [e.target.name]: e.target.value });
    };

    const choices: ICustomQuestions.ISingleSelectOption[] = item.choices.map(
        choice => {
            const showOther = choice.startsWith(
                CUSTOM_QUESTIONS.OTHER_OPT_PREFIX
            );
            return {
                label: choice.replace(CUSTOM_QUESTIONS.OTHER_OPT_PREFIX, ""),
                value: choice,
                inputName: item.uuid,
                ...(showOther && { showOther }),
            };
        }
    );

    const errText = isOtherSelected
        ? "Please enter a valid answer"
        : "Please select an option";

    const selectedValue = choices.find(
        choice => choice.value === castedUserInput
    );
    return (
        <div className={classNames(styles.fieldContainer, styles.flexCol)}>
            <DropdownSelector
                id={htmlId}
                className={styles.dropdownSelector}
                error={showInputError && isEmpty(castedUserInput)}
                name={item.uuid}
                onChange={dropdownValueChange}
                value={selectedValue}
                options={choices}
                autoSelect="FIOOO"
                menuPlacement="bottom"
                maxMenuHeight={maxMenuHeight}
            />
            {isOtherSelected ? (
                <div className={classNames(styles.otherTextContainer)}>
                    <input
                        name={item.uuid}
                        id={`${htmlId}-other`}
                        placeholder="Enter Answer"
                        className={classNames(
                            styles.formInput,
                            styles.otherTextField,
                            showInputError && isEmpty(castedUserInput)
                                ? styles.formInputError
                                : false
                        )}
                        type="text"
                        onChange={setCustomValue}
                    />
                </div>
            ) : null}
            <div className={styles.fieldError}>
                {showInputError &&
                (item.is_mandatory ? isEmpty(castedUserInput) : true) ? (
                    errText
                ) : (
                    <>&nbsp;</>
                )}
            </div>
        </div>
    );
};

export default SingleSelectQuestion;
