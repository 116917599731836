/* eslint-disable no-case-declarations */
import { ICartPage, IEvent, IUser } from "@Interfaces";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    SELECTORS,
    STORAGE_KEYS,
    createAction,
    getListingIsClass,
    isBrowser,
    listingIsAppointment,
    listingIsMerch,
    listingIsSlotType,
} from "@Utils";
import { appendQueryParams } from "@Utils/urls";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { checkIsObjectEmpty } from "repoV2/utils/common/dataTypes/object";
import { SESSION_STORAGE_KEYS } from "repoV2/constants/common/storage/sessionStorage";
import { IUtmParams } from "repoV2/interfaces/IUtmParams";
import { initAndGetReferrerWithLogic } from "repoV2/utils/common/analytics/referrer";
import { getUtmParams } from "repoV2/utils/common/analytics/utmParams";
import { makePaymentViaStripe } from "repoV2/utils/payment/Stripe/makePaymentViaStripe";
import { makePaymentViaTazapay } from "repoV2/utils/payment/Tazapay/makePaymentViaTazapay";
import {
    GATEWAYS_ENUM,
    GATEWAYS_SUPPORTED,
    TRANSACTION_STATUSES,
} from "repoV2/constants/payment";
import {
    getSessionStorageItem,
    setSessionStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { isInstallmentPaymentType } from "repoV2/utils/common/listing";
import { getReturnUrl } from "repoV2/utils/payment/getReturnUrl";
import { IAvailabilityApiResponse } from "./event";

type ICommonPayload = {
    username: string | null;
    listingID: string;
    listingMap: Object;
};

function* computeCommonPayload() {
    const username: IUser.IStore["username"] = yield select(SELECTORS.username);
    const paymentInfo: IEvent.IStore["paymentInfo"] = yield select(
        SELECTORS.paymentInfo
    );
    const answers = paymentInfo?.answers || {};

    let listingMap = {};
    const listingArray = Object.values(
        (getSessionStorageItem(STORAGE_KEYS.CART) as ICartPage.ICart)
            ?.listingMap || {}
    );
    listingArray.forEach(listing => {
        const eventData = listing?.eventData;
        const isFlexibleAppointment =
            listingIsAppointment(eventData?.type) &&
            eventData?.metadata?.is_flexible_scheduling;
        const { instalments, selectedPaymentType, quantity } = listing || {};
        const [initialInstalmentPayment] = instalments || []; // initial payment for instalment plan, first instalment in instalments array is initial payment for instalments plan
        const { uuid: initialInstalmentUuid } = initialInstalmentPayment || {};

        listingMap = {
            ...listingMap,
            [eventData?.uuid]: {
                ...(listingIsMerch(eventData?.type) && {
                    quantity,
                    ...(eventData?.selectedVariant && {
                        variant_uid: eventData?.selectedVariant?.uuid,
                    }),
                }),
                ...(isFlexibleAppointment && {
                    quantity,
                }),
                ...(getListingIsClass(eventData?.type) && {
                    class_uid: listing?.slots[0],
                }),
                ...(listingIsSlotType(eventData?.type) && {
                    slot_uids: listing?.slots,
                }),
                ...(isInstallmentPaymentType(selectedPaymentType) && {
                    installment_uuid: initialInstalmentUuid,
                    payment_type: selectedPaymentType,
                }),

                discount_code: listing?.discount?.discountCode,
                transaction_answers: {
                    [username!]: (eventData?.q_and_a || []).map(q => {
                        const { uuid } = q;
                        return {
                            ques: uuid,
                            ans: answers?.[1]?.[uuid],
                        };
                    }),
                },
                // selectedOfferingVariation is the data of the offering variation selecte dby the user
                // incase we have one, we pass its uuid in 'offering_variant_uuid', so the price will be shown
                // in accordance to that
                ...(!checkIsObjectEmpty(
                    eventData?.selectedOfferingVariation || {}
                ) && {
                    offering_variant_uuid:
                        eventData.selectedOfferingVariation?.uuid,
                }),
            },
        };
    });

    const bookingData = {
        username,
        listingID: listingArray[0].eventData.uuid,
        listingMap,
    };

    return bookingData;
}

function* getAvailability(props: any) {
    const payload = props?.payload;

    // Callback to stop spinner on the event page. To be propagated to all the following sagas
    const paymentErrorCallback: () => void =
        payload?.apiCallArgs?.errorCallback;
    const paymentFinallyCallback: () => void =
        payload?.apiCallArgs?.finallyCallback;
    const paymentAvailabilitySuccessCallback: () => void =
        payload?.apiCallArgs?.paymentAvailabilitySuccessCallback;
    const requestId = payload?.response?.data?.request_id;

    const bookingData: ICommonPayload = yield call(computeCommonPayload);

    yield put(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.CART_PAYMENT_AVAILABILITY,
            payload: {
                verification_request_id: requestId,
                username: bookingData.username,
                listing_uid: bookingData.listingID, // Base listing
                total_customers: 1,
                listing_map: bookingData.listingMap, // Listings details for all
            },
            successCallback: paymentAvailabilitySuccessCallback,
            errorCallback: paymentErrorCallback,
            finallyCallback: paymentFinallyCallback,
            metadata: { ...payload?.metadata }, // Pass along the metadata
        })
    );
}

function* initiatePayment(props: any) {
    const propsPayload = props?.payload;
    const metadata = propsPayload?.metadata;
    const availabilityApiResponse = props?.payload
        ?.response as IAvailabilityApiResponse;

    const {
        isUserDetailsUpdateOtpVerificationSuccessful,
        isCustomerDetailsUpdationAllowed,
        customers,
    } = metadata;

    // stop payment flow if one the user account details already exist and ask if the user wants to update thier account details
    if (
        isCustomerDetailsUpdationAllowed &&
        availabilityApiResponse.data?.has_user_details_changed &&
        !isUserDetailsUpdateOtpVerificationSuccessful
    )
        return;

    // Callback to stop spinner on the event page. To be propagated to all the following sagas
    const paymentErrorCallback: () => void =
        propsPayload?.apiCallArgs?.errorCallback;
    const paymentFinallyCallback: () => void =
        propsPayload?.apiCallArgs?.finallyCallback;

    const bookingData: ICommonPayload = yield call(computeCommonPayload);

    const showFullAddressDetails = metadata?.hasToShowFullAddress;
    // Address data for stripe, coming from the UserDetails form
    // This is added to the metadata in the handleLogin function in eventPage/root
    const stripeCustomerDetails: { [k: string]: string }[] =
        metadata?.stripeCustomerDetails || [];
    const { customPaymentMethod } = metadata;
    const isPayPalEnabled = customPaymentMethod === GATEWAYS_ENUM.PAYPAL;
    if (showFullAddressDetails) {
        setSessionStorageItem(
            STORAGE_KEYS.STRIPE_CUSTOMER_DETAILS,
            stripeCustomerDetails
        );
    }

    const utmDetails: IUtmParams & { referer?: string } = getUtmParams();

    const referer = initAndGetReferrerWithLogic();
    if (referer) {
        utmDetails.referer = referer;
    }
    const usernameList: IUser.IStore["usernameList"] = yield select(
        SELECTORS.usernameList
    );

    const returnUrl = getReturnUrl({
        isDirectBooking: false,
        customPrice: null,
        eventId: bookingData.listingID,
    });

    const payload = {
        username: bookingData.username,
        customers,
        utm_details: utmDetails,
        // dont pass query params to avoid carry forward of transaction status and id to next payment api call in case of failure
        return_url: returnUrl,
        gateways_supported: isPayPalEnabled
            ? [GATEWAYS_ENUM.RAZORPAY] // For paypal payment gateway used is razorpay only hence se send razorpay
            : GATEWAYS_SUPPORTED,
        cart_payment: true,
        listing_map: bookingData.listingMap,
        listing_id: bookingData.listingID,

        ...(showFullAddressDetails && {
            address_details: Object.assign(
                {},
                ...stripeCustomerDetails.map(customerDetails => ({
                    [bookingData.username!]: customerDetails,
                }))
            ),
        }),
        ...(isPayPalEnabled && {
            is_paypal_order: true,
        }),

        // Customer address
        ...(metadata?.addressDetails && {
            address_details: Object.assign(
                {},
                ...metadata.addressDetails.map(
                    (customerDetails: string, i: number) => ({
                        [usernameList[i]]: customerDetails,
                    })
                )
            ),
        }),
    };

    yield put(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.PAYMENT_INITIATE,
            payload,
            metadata: { ...propsPayload?.metadata }, // Pass along the metadata
            errorCallback: paymentErrorCallback,
            successCallback: ({ response }) => {
                if (!isBrowser()) return {};
                const {
                    message,
                    status,
                    data: {
                        options,
                        gateway,
                        tid: transactionID,
                        order,
                        redirect_url: freeListingRedirectUrl = "", // Incase creator wants its users to be redirected to a specific url incase of the
                        // free listings, then we redirect them to this url
                        // freeListingRedirectUrl gets priority over returnUrl
                        client_secret: clientSecret,
                        // client_secret is needed for stripe
                        response: internalResponse, // internalResponse is needed for tazapay
                    },
                } = response;

                if (status !== 200) {
                    throw new Error(
                        message || "An error occurred. Please try again."
                    );
                }
                if (!gateway) {
                    throw new Error("Gateway not specified");
                }
                setSessionStorageItem(
                    SESSION_STORAGE_KEYS.PAYMENT_STATUS_GATEWAY_EXISTS,
                    true
                );
                setSessionStorageItem(
                    SESSION_STORAGE_KEYS.PAYMENT_PAGE_REFERRER,
                    window.location.href
                );
                switch (gateway.toLowerCase()) {
                    case GATEWAYS_ENUM.RAZORPAY.toLowerCase():
                        const rzp = new window.Razorpay(options);
                        rzp?.open();
                        break;

                    // TODO @aakarsh: Remove by Aug 4 if not used
                    // case GATEWAYS_ENUM.JUSPAY.toLowerCase():
                    //     if (order?.payment_links?.web) {
                    //         window.open(order.payment_links.web, "_self");
                    //     }
                    //     break;

                    // This ensures that the Juspay API is loaded and calling the SDK doesn't throw an error
                    case GATEWAYS_ENUM.NEW_JUSPAY.toLowerCase(): {
                        // getting web as weblink to redirect coming from backend
                        if (order?.web) window.location.href = order?.web;

                        break;
                    }

                    // This ensures that the Tazapay API is loaded and calling the SDK doesn't throw an error
                    case GATEWAYS_ENUM.TAZAPAY.toLowerCase(): {
                        makePaymentViaTazapay(internalResponse);

                        break;
                    }

                    case GATEWAYS_ENUM.STRIPE.toLowerCase():
                        // This event trigger tells the stripe component to show itself and collect the user's card
                        // TODO: Replace this with saga channels implementation
                        makePaymentViaStripe(clientSecret);
                        break;

                    case GATEWAYS_ENUM.FREE.toLowerCase():
                        const url =
                            freeListingRedirectUrl ||
                            appendQueryParams(window.location.href, {
                                transaction: transactionID,
                                status: TRANSACTION_STATUSES.SUCCESS,
                            });
                        window.open(url, "_self"); // TODO: Use Router instead here?
                        break;
                    default:
                        break;
                }
                return {};
            },
            finallyCallback: paymentFinallyCallback,
        })
    );
}

export default function* eventSaga() {
    yield takeEvery(ACTION_TYPES.CART.CHECK_AVAILABILITY, getAvailability);
    yield takeEvery(ACTION_TYPES.CART.UPDATE_AVAILABILITY, initiatePayment);
}
