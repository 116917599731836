import { Action as BaseAction } from "redux";
import { IFetchDataAction } from "@Redux/sagas/api";

export const ACTION_TYPES = {
    HOST: {
        SET_HOST: "@HOST/SET_HOST",
        IS_VALID: "@HOST/IS_VALID",
        UPDATE_DATA: "@HOST/UPDATE_DATA",
        UPDATE_SITE_BUILDER_DATA: "@HOST/UPDATE_SITE_BUILDER_DATA",
        UPDATE_THEME_DATA: "@HOST/UPDATE_THEME_DATA", // For API calls on end-user side
        UPDATE_USER_IP: "@HOST/UPDATE_USER_IP",
        REFETCH_DATA: "@HOST/REFETCH_DATA",
        UPDATE_USER_LOCALE: "@HOST/UPDATE_USER_LOCALE",
        UPDATE_PLAN_LIST: "@HOST/UPDATE_PLAN_LIST",
        UPDATE_PLAN_DETAILS: "@EVENT/UPDATE_PLAN_DETAILS",
        SET_CURRENT_PLAN_ID: "@HOST/SET_CURRENT_PLAN_ID",
        UPDATE_TEAM_DATA: "@EVENT/UPDATE_TEAM_DATA",
        UPDATE_CONTENT_LIST: "@HOST/UPDATE_CONTENT_LIST",
        UPDATE_CONTENT_DETAILS: "@HOST/UPDATE_CONTENT_DETAILS",
        UPDATE_WATERMARK_TYPE: "@HOST/UPDATE_WATERMARK_TYPE",
        UPDATE_CONTENT_COMMENTS: "@HOST/UPDATE_CONTENT_COMMENTS",
        UPDATE_CONTENT_CERTIFICATE: "@HOST/UPDATE_CONTENT_CERTIFICATE",
        UPDATE_BLOG_POSTS: "@HOST/UPDATE_BLOG_POSTS",
        UPDATE_BLOG_POST_DETAILS: "@HOST/UPDATE_BLOG_POST_DETAILS",
        UPDATE_BLOG_POST_COMMENTS: "@HOST/UPDATE_BLOG_POST_COMMENTS",
        UPDATE_COMMENT_REPLIES: "@HOST/UPDATE_COMMENT_REPLIES",
        UPDATE_BLOG_SUBSCRIPTION_EMAIL: "@HOST/UPDATE_BLOG_SUBSCRIPTION_EMAIL",
    },
    EVENT: {
        UPDATE_DATA: "@EVENT/UPDATE_DATA",
        UPDATE_DISCOUNT: "@EVENT/UPDATE_DISCOUNT",
        UPDATE_APPLIED_DISCOUNT_CODE_DETAILS:
            "@EVENT/UPDATE_APPLIED_DISCOUNT_CODE_DETAILS",
        UPDATE_LOYALTY_DISCOUNT_LIST: "@EVENT/UPDATE_LOYALTY_DISCOUNT_LIST",
        RESET_LOYALTY_DISCOUNT_LIST: "@EVENT/RESET_LOYALTY_DISCOUNT_LIST",
        APPLY_DISCOUNT: "@EVENT/APPLY_DISCOUNT",
        APPLY_DISCOUNT_ERROR: "@EVENT/APPLY_DISCOUNT_ERROR",
        RESET_DISCOUNT: "@EVENT/RESET_DISCOUNT",

        UPDATE_USERNAME: "@EVENT/UPDATE_USERNAME",
        UPDATE_BOOKING_DATA: "@EVENT/UPDATE_BOOKING_DATA",
        RESET_BOOKING_DATA: "@EVENT/RESET_BOOKING_DATA",

        SET_SELECTED_EVENT: "@EVENT/SET_SELECTED_EVENT",

        CHECK_AVAILABILITY: "@EVENT/CHECK_AVAILABILITY",
        UPDATE_AVAILABILITY: "@EVENT/UPDATE_AVAILABILITY",
        PROCESS_BOOKING: "@EVENT/PROCESS_BOOKING",

        UPDATE_UPSELL_LISTINGS: "@EVENT/UPDATE_UPSELL_LISTINGS", // Update the upsell listing data into the store

        FETCH_REPAYMENT_DATA: "@EVENT/FETCH_REPAYMENT_DATA",

        SET_LISTING_MISCELLANEOUS_DATA: "@EVENT/SET_LISTING_MISCELLANEOUS_DATA",
        SET_OFFERING_VARIATIONS: "@EVENT/SET_OFFERING_VARIATIONS",
        SET_SELECTED_OFFERING_VARIATION:
            "@EVENT/SET_SELECTED_OFFERING_VARIATION",

        UPDATE_EVENT_LISTING_DATA: "@EVENT/UPDATE_EVENT_LISTING_DATA",
        SET_SHIPPING_DETAILS: "@EVENT/SET_SHIPPING_DETAILS",

        SET_SELECTED_VARIANT_BY_UUID: "@EVENT/SET_SELECTED_VARIANT_BY_UUID",
    },
    CART: {
        CHECK_AVAILABILITY: "@CART/CHECK_AVAILABILITY",
        UPDATE_AVAILABILITY: "@CART/UPDATE_AVAILABILITY",
    },
    UTILS: {
        API_CALL: "@UTILS/API_CALL",
        UPDATE_API_CALL_STATUS: "@UTILS/UPDATE_API_CALL_STATUS",
        SHOW_ALERT: "@UTILS/SHOW_ALERT",
        POST_ANALYTICS: "@UTILS/POST_ANALYTICS",
        SET_CAN_RENDER: "@UTILS/SET_CAN_RENDER",
        SET_MODAL: "@UTILS/SET_MODAL",
        RESET_MODAL: "@UTILS/RESET_MODAL",
        // PUSH_NOTIF: "@UTILS/PUSH_NOTIF",
        LOG_ERROR: "@UTILS/LOG_ERROR",
        REDIRECT_AWAY: "@UTILS/REDIRECT_AWAY",
        START_PROCESSING: "@UTILS/START_PROCESSING", // A small handy util boolean value to keep track of whether something is processing
        STOP_PROCESSING: "@UTILS/STOP_PROCESSING",
        SET_DATA: "@UTILS/SET_DATA",
        DELETE_DATA: "@UTILS/DELETE_DATA",
        VALIDATE_IP: "@UTILS/VALIDATE_IP",
        VALIDATE_IP_FALLBACK: "@UTILS/VALIDATE_IP_FALLBACK",
        VALIDATE_IP_2ND_FALLBACK: "@UTILS/VALIDATE_IP_2ND_FALLBACK",
        UPDATE_COMMUNITY_APP_DATA: "@UTILS/UPDATE_COMMUNITY_APP_DATA",
        RESET_COMMUNITY_APP_DATA: "@UTILS/RESET_COMMUNITY_APP_DATA",
    },
    USER: {
        UPDATE_AUTH_TOKEN: "@USER/UPDATE_AUTH_TOKEN",
        UPDATE_EMAIL: "@USER/UPDATE_EMAIL",
        UPDATE_USERNAME: "@USER/UPDATE_USERNAME",
        UPDATE_RENEW_USERNAME: "@USER/UPDATE_RENEW_USERNAME",
        RESET_EMAIL: "@USER/RESET_EMAIL",
        VERIFY_OTP: "@USER/VERIFY_OTP",

        // TODO: delete after migration to formv2
        SET_FORM_DETAILS: "@USER/SET_FORM_DETAILS",
        INITIATE_FORM_FIELDS: "@USER/INITIATE_FORM_FIELDS",
        CLEAR_FORM_DETAILS: "@USER/CLEAR_FORM_DETAILS",

        UPDATE_ORDER_DETAILS: "@USER/UPDATE_ORDER_DETAILS",
        SET_BOOKINGS_OF_SKU_TYPE: "@USER/SET_BOOKINGS_OF_SKU_TYPE",
        SET_PURCHASE_HISTORY: "@USER/SET_PURCHASE_HISTORY",
        UPDATE_ANSWERS: "@USER/UPDATE_ANSWERS",
        UPDATE_NOTIFICATIONS: "@USER/UPDATE_NOTIFICATIONS",
        INITIATE_FORM: "@USER/INITIATE_FORM",
        SET_FORM_DATA: "@USER/SET_FORM_DATA",
        RESET_FORM_DATA: "@USER/RESET_FORM_DATA",
        DELETE_FORM: "@USER/DELETE_FORM",
        ADD_FORM_FIELDS: "@USER/ADD_FORM_FIELDS",
        DELETE_FORM_FIELDS: "@USER/DELETE_FORM_FIELDS",
    },
    PROPS: {
        COMPONENT_PROPS: {
            UPDATE_UPSELL_COMPONENT_PROPS:
                "@PROPS/EVENT/UPDATE_UPSELL_COMPONENT_PROPS",
            UPDATE_PAYMENT_SUMMARY_COMPONENT_PROPS:
                "@PROPS/EVENT/UPDATE_PAYMENT_SUMMARY_COMPONENT_PROPS",
        },
    },
};

// * IMPORTANT
// TODO: Move to type utils
export type ValueOf<T> = T[keyof T];

export namespace IAction {
    export type IActionTypes =
        | ValueOf<typeof ACTION_TYPES.HOST>
        | ValueOf<typeof ACTION_TYPES.EVENT>
        | ValueOf<typeof ACTION_TYPES.USER>
        | ValueOf<typeof ACTION_TYPES.UTILS>;
    export interface Action<T> extends BaseAction<T> {
        payload?: IPayloadProps;
    }

    // Adding the keys here as we go making different actions
    // TODO: Turn this interface into a union type of all possible combinations
    export interface IPayloadProps {
        response?: any;
        apiCallArgs?: any;
        payload?: any;
        apiActionType?: any;
        urlArgs?: any;
        queryParams?: any;
        headers?: any;

        successCallback?: (k: IFetchDataAction.IReturnPayload) => any;
        errorCallback?: (k: IFetchDataAction.IReturnPayload) => any;
        finallyCallback?: (k: IFetchDataAction.IReturnPayload) => any;

        successAction?: string | Array<string>;
        errorAction?: string | Array<string>;

        metadata?: any; // Metadata to be used by subsequent redux actions

        [k: string]: any;
    }
}

export const createAction = (
    type: IAction.IActionTypes,
    payload: IAction.IPayloadProps = {}
): IAction.Action<any> => ({
    type,
    payload,
});
