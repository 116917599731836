import {
    ACTION_TYPES,
    isEmpty,
    LISTING_MISCELLANEOUS_DATA_TYPES,
    LISTING_REVIEW_STATUS,
} from "@Utils";

import { IAction, IEvent } from "@Interfaces";
import { getVariantMapFromKey } from "@Modules/eventPage/components/VariantSelector/utils/utils";
import { roundOff } from "@Utils/number";
import initialState from "../initialState";

export default function eventReducer(
    state = initialState?.event || {},
    { type, payload }: IAction<any>
): IEvent.IStore {
    const apiCallPayload = payload?.apiCallArgs?.payload;
    const apiResponse = payload?.response;
    switch (type) {
        case ACTION_TYPES.EVENT.UPDATE_DATA: {
            const eventData: IEvent.IEventData = payload?.response?.data?.event;
            return {
                ...state,
                list: {
                    ...state.list,
                    ...(eventData && { [eventData?.uuid]: { ...eventData } }),
                },
                // TODO: Add a metadata key to control the behaviour below
                ...(eventData && { selectedEvent: eventData?.uuid }),
            };
        }

        case ACTION_TYPES.EVENT.UPDATE_DISCOUNT:
            return {
                ...state,
                discount: {
                    discountEntered: payload?.discount || "",
                    appliedDiscountCodeType:
                        payload?.appliedDiscountCodeType ||
                        state.discount.appliedDiscountCodeType,
                    discountError: null,
                    discountCode: null,
                    discountData: null,
                },
            };

        case ACTION_TYPES.EVENT.SET_OFFERING_VARIATIONS: {
            const selectedEventUuid = state.selectedEvent;
            const selectedEvent = selectedEventUuid
                ? state.list[selectedEventUuid]
                : null;
            return {
                ...state,
                list: {
                    ...state.list,
                    ...(selectedEventUuid &&
                        selectedEvent && {
                            [selectedEventUuid]: {
                                ...selectedEvent,
                                offering_variation_short_description:
                                    apiResponse?.data?.short_description,
                                offeringVariations:
                                    apiResponse?.data?.offering_variants,
                            },
                        }),
                },
            };
        }

        case ACTION_TYPES.EVENT.SET_SELECTED_OFFERING_VARIATION: {
            const selectedEventUuid = state.selectedEvent;
            const selectedEvent = selectedEventUuid
                ? state.list[selectedEventUuid]
                : null;
            return {
                ...state,
                list: {
                    ...state.list,
                    ...(selectedEventUuid &&
                        selectedEvent && {
                            [selectedEventUuid]: {
                                ...selectedEvent,
                                selectedOfferingVariation: payload,
                            },
                        }),
                },
            };
        }

        case ACTION_TYPES.EVENT.SET_LISTING_MISCELLANEOUS_DATA: {
            const reviews: Array<IEvent.IReview> = [];
            let highlights;
            let requirements;

            apiResponse?.data?.miscellaneous_data.forEach((item: any) => {
                if (
                    item.type === LISTING_MISCELLANEOUS_DATA_TYPES.reviews.value
                ) {
                    reviews.push(item);
                }

                if (
                    item.type ===
                    LISTING_MISCELLANEOUS_DATA_TYPES.highlights.value
                ) {
                    highlights = item.data;
                }

                if (
                    item.type ===
                    LISTING_MISCELLANEOUS_DATA_TYPES.requirements.value
                ) {
                    requirements = item.data;
                }
            });
            let avgRating = 0;
            let reviewList;
            if (!isEmpty(reviews)) {
                let sum = 0;
                let count = 0;
                reviewList = reviews
                    .filter(
                        (item: any) =>
                            item?.data?.status === LISTING_REVIEW_STATUS.live
                    )
                    ?.map((item: any) => {
                        if (item?.data?.rating > 0) {
                            sum += item?.data?.rating;
                            count += 1;
                        }
                        return {
                            image: item?.data?.image,
                            name: item?.data?.name,
                            sub_heading: item?.data?.sub_heading,
                            description: item?.data?.description,
                            rating: item?.data?.rating,
                            uuid: item.uuid,
                        };
                    })
                    .sort((a: any, b: any) => {
                        return b.rating - a.rating;
                    });
                if (sum && sum > 0 && count > 0) {
                    avgRating = sum / count;
                }
            }

            return {
                ...state,
                miscellaneousData: {
                    reviews: {
                        avgRating: parseFloat(roundOff(avgRating, 2)),
                        data: reviewList,
                    },
                    highlights,
                    requirements,
                },
            };
        }

        case ACTION_TYPES.EVENT.UPDATE_APPLIED_DISCOUNT_CODE_DETAILS:
            return {
                ...state,
                discount: {
                    ...state.discount,
                    appliedDiscountCodeType: payload?.appliedDiscountCodeType,
                    customerEmail: payload?.customerEmail,
                },
            };

        case ACTION_TYPES.EVENT.UPDATE_LOYALTY_DISCOUNT_LIST:
            return {
                ...state,
                loyaltyDiscounts: {
                    data: payload?.response?.data?.order || [],
                },
            };

        case ACTION_TYPES.EVENT.RESET_LOYALTY_DISCOUNT_LIST:
            return {
                ...state,
                loyaltyDiscounts: {
                    data: [],
                },
            };

        case ACTION_TYPES.EVENT.APPLY_DISCOUNT:
            return {
                ...state,
                discount: {
                    ...state.discount,
                    discountEntered: apiCallPayload?.code,
                    discountCode: apiCallPayload?.code,
                    discountData: apiResponse?.data?.discount,
                    discountError: null,
                    appliedDiscountCodeType: apiCallPayload?.discountCodeType,
                    customerEmail: apiCallPayload?.customer_email,
                },
            };

        case ACTION_TYPES.EVENT.APPLY_DISCOUNT_ERROR:
            return {
                ...state,
                discount: {
                    ...state.discount,
                    discountEntered: apiCallPayload?.code,
                    discountCode: apiCallPayload?.code,
                    discountData: null,
                    discountError: apiResponse?.message,
                    appliedDiscountCodeType: "not applied",
                },
            };

        case ACTION_TYPES.EVENT.RESET_DISCOUNT:
            return {
                ...state,
                discount: {
                    discountEntered: "",
                    appliedDiscountCodeType: "not applied",
                    discountCode: null,
                    discountData: null,
                    discountError: null,
                },
            };

        case ACTION_TYPES.EVENT.SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent: payload?.selectedEvent ?? null,
            };

        case ACTION_TYPES.EVENT.UPDATE_BOOKING_DATA:
            // selectedEvent can be updated from both here and SET_SELECTED_EVENT for legacy support
            // eslint-disable-next-line no-case-declarations
            const { selectedEvent, ...rest } = payload;
            return {
                ...state,
                // Cannot set selectedEvent to null from here.
                // Have to use @EVENT/SET_SELECTED_EVENT for that
                ...(selectedEvent && {
                    selectedEvent,
                }),
                paymentInfo: {
                    ...state.paymentInfo,
                    ...rest,
                },
            };

        case ACTION_TYPES.EVENT.RESET_BOOKING_DATA:
            return {
                ...state,
                paymentInfo: { ...initialState.event.paymentInfo },
            };

        case ACTION_TYPES.EVENT.UPDATE_AVAILABILITY:
            return {
                ...state,
                paymentInfo: {
                    ...state.paymentInfo,
                    isAvailable: apiResponse?.status === 200,
                },
            };

        case ACTION_TYPES.EVENT.UPDATE_UPSELL_LISTINGS:
            return {
                ...state,
                upsellListings: {
                    [payload?.apiCallArgs?.urlArgs?.listingID]: (payload
                        ?.response?.data?.upsell_listings ||
                        []) as Array<IEvent.IUpsellOffering>,
                },
            };

        case ACTION_TYPES.EVENT.UPDATE_EVENT_LISTING_DATA: {
            // appending variants data to current event id list
            const eventId = payload?.payload?.eventId;
            const source = payload?.payload?.source;
            const list = state.list[eventId];
            return {
                ...state,
                list: {
                    ...state.list,
                    [eventId]: {
                        ...list,
                        [source]: payload?.payload?.data,
                    },
                },
            };
        }

        case ACTION_TYPES.EVENT.SET_SELECTED_VARIANT_BY_UUID: {
            const eventId = payload?.payload?.eventId;
            const variantUuid = payload?.payload?.variantUuid;
            const list = state.list[eventId];
            const { variantsData } = list;
            const { variants = {} } = variantsData || {};
            const [variantCombination, newSelectedVariant] =
                Object.entries(variants).find(
                    ([, variant]) => variant.uuid === variantUuid
                ) || [];
            const newSelectedVariantCombination =
                getVariantMapFromKey(variantCombination);

            return {
                ...state,
                list: {
                    ...state.list,
                    [eventId]: {
                        ...list,
                        selectedVariant: newSelectedVariant,
                        selectedVariantCombination:
                            newSelectedVariantCombination,
                    },
                },
            };
        }

        case ACTION_TYPES.EVENT.SET_SHIPPING_DETAILS: {
            const eventId = payload?.payload?.eventId;
            const list = state.list[eventId];
            const prevShippingDetails = list?.shipping_details;
            return {
                ...state,
                list: {
                    ...state.list,
                    [eventId]: {
                        ...list,
                        shipping_details: {
                            ...prevShippingDetails,
                            ...payload?.payload?.data,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
}
