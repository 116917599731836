import { format, set } from "date-fns";
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseDateTime } from "@Utils/parseDateTime";
import { DEFAULT_API_PAYLOAD_DATETIME_FORMAT } from "repoV2/features/Common/modules/Time/Time.constants";
import styles from "./DateInput.module.scss";

/**
 * - extraProps can have following keys
 *      - value?: string;
 *      - onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
 *      - name?: string;
 *      - onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
 *      - onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
 */

/**
 *
 * This calendar input reads and writes data as a timezone encoded string
 * with the format being the same as that of moment's default `.format()` output.
 * A reference to the `event: React.ChangeEvent<HTMLInputElement>` is still
 * passed as the first parameter when the external `onChange` prop is called, but
 * it is recommended that the second param, the parsedValue, be used to read the data.
 */
export const DateInput = ({
    design = "rounded_corners",
    className,
    applyTheme,
    error,
    editingDisabled,
    onChange: onChangeProp,
    value: valueProp,
    ...extraProps
}: {
    design?: "rounded_corners";
    className?: string;
    applyTheme?: boolean;
    error?: boolean;
    editingDisabled?: boolean;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        parsedValue: string
    ) => void;
    value: string;
    [k: string]: any; // for extraProps
}) => {
    // Refer the note above this DateInput component
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const parsedValue = format(
            set(new Date(e.target.value), {
                hours: 0,
                minutes: 0,
                seconds: 0,
            }),
            DEFAULT_API_PAYLOAD_DATETIME_FORMAT
        );
        onChangeProp?.(e, parsedValue);
    };
    switch (design) {
        case "rounded_corners":
        default:
            return (
                <input
                    type="date"
                    className={classNames(
                        styles.roundedCorners,
                        error && styles.inputError,
                        editingDisabled && styles.editingDisabled,
                        applyTheme && styles.applyTheme,
                        className
                    )}
                    disabled={editingDisabled}
                    onChange={onChange}
                    value={
                        valueProp ? parseDateTime(valueProp, "yyyy-MM-dd") : ""
                    }
                    {...extraProps}
                />
            );
    }
};

DateInput.defaultProps = {
    design: "rounded_corners",
    error: undefined,
    className: undefined,
    applyTheme: false,
    editingDisabled: undefined,
};
